import vendasOffline from '../../assets/GestaoDeRedes/Options_1/ganharSeguidores.svg'
import remarketing from '../../assets/GestaoDeRedes/Options_1/realizarVendas.svg'
import checkoutDeCompras from '../../assets/GestaoDeRedes/Options_1/terPresenca.svg'
import fortalecerAutoridade from '../../assets/GestaoDeRedes/Options_1/construirAudiencia.svg'

import face from '../../assets/GestaoDeRedes/Options_2/face.svg'
import insta from '../../assets/GestaoDeRedes/Options_2/insta.svg'
import insta_face from '../../assets/GestaoDeRedes/Options_2/insta_face.svg'

import sim from '../../assets/GestaoDeRedes/Options_3/sim.svg'
import nao from '../../assets/GestaoDeRedes/Options_3/nao.svg'
import naoTenhoLojaFisica from '../../assets/GestaoDeRedes/Options_3/naoMasQueroCriar.svg'
import naoSeiAinda from '../../assets/GestaoDeRedes/Options_3/apenasUmDeles.svg'

import website from '../../assets/GestaoDeRedes/Options_4/todoDia.svg'
import eCommerce from '../../assets/GestaoDeRedes/Options_4/umaVezPorSemana.svg'
import landingPage from '../../assets/GestaoDeRedes/Options_4/quaseNunca.svg'
import naoMaisGostaria from '../../assets/GestaoDeRedes/Options_4/nuncaMovimentei.svg'

import minhaCustodia from '../../assets/GestaoDeRedes/Options_5/sim.svg'
import custodiaTerceiros from '../../assets/GestaoDeRedes/Options_5/nao.svg'
import custodiaTerceirosagencia from '../../assets/GestaoDeRedes/Options_5/naoMaisPretendo.svg'
import naoTenhoPagina from '../../assets/GestaoDeRedes/Options_5/naoSei.svg'

import sim6 from '../../assets/GestaoDeRedes/Options_6/menosDe1Ano.svg'
import nao6 from '../../assets/GestaoDeRedes/Options_6/doisACincoAnos.svg'
import apenasDatasEspeciais from '../../assets/GestaoDeRedes/Options_6/cincoADezAnos.svg'
import naoSeiGostaria from '../../assets/GestaoDeRedes/Options_6/maisDe10Anos.svg'

import sim7 from '../../assets/GestaoDeRedes/Options_7/simJaTenhoTudo.svg'
import nao7 from '../../assets/GestaoDeRedes/Options_7/naoGostariaDeCriar.svg'
import naoMasPretendo7 from '../../assets/GestaoDeRedes/Options_7/apenasLogo.svg'
import naoSei from '../../assets/GestaoDeRedes/Options_7/naoSei.svg'

import visitasNoLocal from '../../assets/GestaoDeRedes/Options_8/divertido.svg'
import acoesDeRua from '../../assets/GestaoDeRedes/Options_8/profissional.svg'
import redesSociais from '../../assets/GestaoDeRedes/Options_8/tecnico.svg'
import pesquisaGoogle from '../../assets/GestaoDeRedes/Options_8/naoSeiAinda.svg'

import anunciarNoGoogle from '../../assets/GestaoDeRedes/Options_9/oMaximoPossivel.svg'
import simTerceiros from '../../assets/GestaoDeRedes/Options_9/diariamente.svg'
import simNaoTiveResultados9 from '../../assets/GestaoDeRedes/Options_9/3xPorSemana.svg'
import naoPrimeiraVez from '../../assets/GestaoDeRedes/Options_9/1xPorSemana.svg'

import redeDePesquisa from '../../assets/GestaoDeRedes/Options_10/oMaximoPossivel.svg'
import googleShopping from '../../assets/GestaoDeRedes/Options_10/diariamente.svg'
import redeDeDisplay from '../../assets/GestaoDeRedes/Options_10/3xPorSemana.svg'
import naoSeiGostariaDeInformacoes9 from '../../assets/GestaoDeRedes/Options_10/1xPorSemana.svg'

import sim11 from '../../assets/GestaoDeRedes/Options_11/oMaximoPossivel.svg'
import nao11 from '../../assets/GestaoDeRedes/Options_11/diariamente.svg'
import simVouPrecisarProduzirVideos from '../../assets/GestaoDeRedes/Options_11/3xPorSemana.svg'
import naoSeiGostariaDeInformacoes11 from '../../assets/GestaoDeRedes/Options_11/euMesmoPretendo.svg'

import proximoMinhaLoja from '../../assets/GestaoDeRedes/Options_12/sim.svg'
import cidadeInteria from '../../assets/GestaoDeRedes/Options_12/porEnquantoNao.svg'
import estadoInteiro from '../../assets/GestaoDeRedes/Options_12/simSomento.svg'
import brasilTodo from '../../assets/GestaoDeRedes/Options_12/naoSei.svg'

import somentoHorarioComercial from '../../assets/GestaoDeRedes/Options_13/sim.svg'
import tempoTodo from '../../assets/GestaoDeRedes/Options_13/nao.svg'
import emPromocoes from '../../assets/GestaoDeRedes/Options_13/simMasEuMesmo.svg'
import naoSeiGostariaDeInformacoes13 from '../../assets/GestaoDeRedes/Options_13/naoSei.svg'

import apenasUm from '../../assets/GestaoDeRedes/Options_14/meuPortifolio.svg'
import de2a5 from '../../assets/GestaoDeRedes/Options_14/carrosseisComDicas.svg'
import de5a10 from '../../assets/GestaoDeRedes/Options_14/apenasMinhasFotos.svg'
import maisDe10 from '../../assets/GestaoDeRedes/Options_14/umMixDeTudo.svg'

import atravesDeFormulario from '../../assets/GestaoDeRedes/Options_15/sim.svg'
import atravesLigacoes from '../../assets/GestaoDeRedes/Options_15/nao.svg'
import atravesDeDM from '../../assets/GestaoDeRedes/Options_15/minhasFotos.svg'
import naoSeiGostariaDeInformacoes15 from '../../assets/GestaoDeRedes/Options_15/naoSeiMasGostaria.svg'

import euMesmo from '../../assets/GestaoDeRedes/Options_16/numeroDeSeguidores.svg'
import alguemDaMinhaEquipe from '../../assets/GestaoDeRedes/Options_16/conversasIniciadas.svg'
import precisoDeUmaConsultoria from '../../assets/GestaoDeRedes/Options_16/engajamentoNosPosts.svg'
import queroColocarChatbots from '../../assets/GestaoDeRedes/Options_16/naoSei.svg'

import sim17 from '../../assets/GestaoDeRedes/Options_17/simImpulsionei.svg'
import nao17 from '../../assets/GestaoDeRedes/Options_17/simJaPossuo.svg'
import simNaoTiveResultados from '../../assets/GestaoDeRedes/Options_17/naoMasPretendo.svg'
import naoMasPretendo from '../../assets/GestaoDeRedes/Options_17/naoPorEquanto.svg'

import relatorioBasico from '../../assets/GestaoDeRedes/Options_18/sim.svg'
import relatorioPersonalidado from '../../assets/GestaoDeRedes/Options_18/nao.svg'
import naoPrecisoDeRelatorio from '../../assets/GestaoDeRedes/Options_18/jaTenhoCriado.svg'
import naoSeiGostariaDeInformacoes from '../../assets/GestaoDeRedes/Options_18/naoSeiGostariadeSaber.svg'

import imediatamente from '../../assets/GestaoDeRedes/Options_20/imediatamente.svg'
import emAte1Semana from '../../assets/GestaoDeRedes/Options_20/emAte1Semana.svg'
import proximasSemanas from '../../assets/GestaoDeRedes/Options_20/proximasSemanas.svg'
import maximo1Mes from '../../assets/GestaoDeRedes/Options_20/1mes.svg'

import individual from '../../assets/form/individual.svg'
import startup from '../../assets/form/startup.svg'
import sme from '../../assets/form/sme.svg'
import agency from '../../assets/form/agency.svg'
import large_enterprise from '../../assets/form/large_enterprise.svg'

import arrowLeft from '../../assets/arrow-left.svg'
import confirm from '../../assets/form/confirm.svg'

import { Container, Content, Footer, ContainerForm, Header, CompanyTypeContainer, RadioBox, ContentHeader } from "../../styles/questionsStyles"
import { FormEvent, useEffect, useState } from 'react'
import { api, ip } from '../../services/api';

import { AnimateSharedLayout, motion } from 'framer-motion';

interface PathSelectionProps {
    onSelectPath: (selectPath: string) => void;
}

interface ErrorProps {
    name: string,
    email: string
}

export function GestaoDeRedes({ onSelectPath }: PathSelectionProps) {

    const [type, setType] = useState('')
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [cupomName, setCupomName] = useState('');

    const [errors, setErrors] = useState<ErrorProps>({
        name: '',
        email: ''
    })

    const [positonPath, setPositionPath] = useState(0)
    const [choice, setChoices] = useState<Object[]>([])
    const [references, setReferences] = useState({ reference1: '', reference2: '' })
    const [isLoading, setIsLoading] = useState(false);

    const containerTransation = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    }

    async function handleExit() {
        const userIP = await ip.get("").then(response => {
            return {
                "Region": response.data.region,
                "IP": response.data.query
            }
        }).catch(() => {
            console.log('Request failed');
        })

        const choices = {
            choices: choice,
            userData: userIP
        }
        const response = await api.post('/abandonedBriefing', choices)
        console.log(response)
    };

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    })

    const alertUser = (event: any) => {
        event.preventDefault();
        event.returnValue = '';
        handleExit();
    }

    function handleNextPositionPath(question: string, choiceSelected: string | object, index: number) {
        if (positonPath > 20) return

        question = question.replace("<mark>", "").replace("</mark>", "")

        const choiceResponce = { "question": question, "answer": choiceSelected }

        const arrayChoice = [...choice]
        arrayChoice[index] = choiceResponce
        setChoices(arrayChoice)

        setPositionPath(positonPath + 1)
        window.scrollTo(0, 0)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setReferences(refer => ({
            ...refer,
            [name]: value
        }));
    }

    async function handleSendBrifing(event: FormEvent) {
        event.preventDefault();
        setIsLoading(true)

        const isFormValid = validateForm();

        if (isFormValid) {

            const userIP = await ip.get("").then(response => {
                return {
                    "Região": response.data.region,
                    "IP": response.data.query
                }
            }).catch(() => {
                console.log('Request failed');
            })

            const newBriefing = {
                name: name,
                email: email,
                phone: phone,
                companyName: companyName,
                type: type,
                choices: choice,
                briefingType: "Gestão de Redes Sociais",
                cupomName: cupomName,
                UserDataIP: userIP
            }

            const response = await api.post('/briefing', newBriefing)
            setIsLoading(false)

            if (response) {
                setPositionPath(positonPath + 1)
                setType('');
                setName('');
                setEmail('');
                setPhone('');
                setCompanyName('');
                setCupomName('');
                setPositionPath(1);
                setChoices(['']);
                setReferences({ reference1: '', reference2: '' });
            }

        } else {
            window.scrollTo(0, 0)
            setIsLoading(false)
        }
    }

    function validateForm() {
        let errors = {
            name: '',
            email: ''
        };
        let isValid = true;

        if (!name.trim()) {
            errors.name = "Campo nome obrigatorio";
            isValid = false;
        }

        if (!email) {
            errors.email = "Email obrigatorio"
            isValid = false;
        } else if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            errors.email = "Email invalido"
            isValid = false;
        }

        setErrors(errors)
        return isValid;
    }

    function handleBackPositionPath() {
        if (positonPath <= 0) onSelectPath('main')

        setPositionPath(positonPath - 1)
    }

    const questions = [
        {
            questionTitle: 'Qual o seu <mark>principal objetivo</mark> nas Redes Sociais?',
            answerOptions: [
                { answerText: 'Ganhar seguidores', icon: vendasOffline },
                { answerText: 'Realizar vendas', icon: remarketing },
                { answerText: 'Ter presença ativa', icon: checkoutDeCompras },
                { answerText: 'Construir uma audiência', icon: fortalecerAutoridade }
            ]
        },
        {
            questionTitle: '<mark>Quais</mark> redes sociais você pretende trabalhar?',
            answerOptions: [
                { answerText: 'Apenas Facebook', icon: face },
                { answerText: 'Apenas Instagram', icon: insta },
                { answerText: 'Ambos', icon: insta_face },
            ]
        },
        {
            questionTitle: 'Você <mark>já possui os perfis</mark> criados?',
            answerOptions: [
                { answerText: 'Sim', icon: sim },
                { answerText: 'Não', icon: nao },
                { answerText: 'Sim, mas quero criar outros do zero', icon: naoTenhoLojaFisica },
                { answerText: 'Apenas um deles', icon: naoSeiAinda }
            ]
        },
        {
            questionTitle: 'Com <mark>qual frequência você movimenta atualmente</mark> as suas redes sociais?',
            answerOptions: [
                { answerText: 'Todo dia', icon: website },
                { answerText: '1x por semana', icon: eCommerce },
                { answerText: 'Quase nunca', icon: landingPage },
                { answerText: 'Nunca movimentei', icon: naoMaisGostaria }
            ]
        },
        {
            questionTitle: 'Você já <mark>comprou seguidores?',
            answerOptions: [
                { answerText: 'Sim', icon: minhaCustodia },
                { answerText: 'Não', icon: custodiaTerceiros },
                { answerText: 'Não, mas pretendo', icon: custodiaTerceirosagencia },
                { answerText: 'Não sei', icon: naoTenhoPagina }
            ]
        },
        {
            questionTitle: 'Há <mark>quanto tempo</mark> sua empresa está <mark>no mercado</mark> ?',
            answerOptions: [
                { answerText: 'Menos de 1 ano', icon: sim6 },
                { answerText: '2 a 5 anos', icon: nao6 },
                { answerText: '5 a 10 anos', icon: apenasDatasEspeciais },
                { answerText: 'Mais de 10 anos', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Você já tem a <mark>Identidade Visual</mark> da sua marca (logo, tipografia, cores, etc)?',
            answerOptions: [
                { answerText: 'Sim, já tenho tudo', icon: sim7 },
                { answerText: 'Não, gostaria de criá-la', icon: nao7 },
                { answerText: 'Apenas a logo', icon: naoMasPretendo7 },
                { answerText: 'Não sei', icon: naoSei }
            ]
        },
        {
            questionTitle: 'Qual o <mark>tom da comunicação</mark> que você deseja?',
            answerOptions: [
                { answerText: 'Divertido', icon: visitasNoLocal },
                { answerText: 'Profissional', icon: acoesDeRua },
                { answerText: 'Técnico', icon: redesSociais },
                { answerText: 'Não sei ainda', icon: pesquisaGoogle }
            ]
        },
        {
            questionTitle: '<mark>Com qual frequência</mark> você deseja que sejam realizadas as <mark>postagens</mark> ?',
            answerOptions: [
                { answerText: 'O máximo possível', icon: anunciarNoGoogle },
                { answerText: 'Diariamente', icon: simTerceiros },
                { answerText: '3x por semana', icon: simNaoTiveResultados9 },
                { answerText: '1x por semana', icon: naoPrimeiraVez }
            ]
        },
        {
            questionTitle: 'Com qual frequência você deseja inserir conteúdo no <mark>Feed?</mark>',
            answerOptions: [
                { answerText: 'O máximo possível', icon: redeDePesquisa },
                { answerText: 'Diariamente', icon: googleShopping },
                { answerText: '3x por semana', icon: redeDeDisplay },
                { answerText: '1x por semana', icon: naoSeiGostariaDeInformacoes9 }
            ]
        },
        {
            questionTitle: 'Com qual frequência você deseja inserir conteúdo no <mark>Stories?</mark>',
            answerOptions: [
                { answerText: 'O máximo possível', icon: sim11 },
                { answerText: 'Diariamente', icon: nao11 },
                { answerText: '3x por semana', icon: simVouPrecisarProduzirVideos },
                { answerText: 'Eu mesmo pretendo movimentar os stories', icon: naoSeiGostariaDeInformacoes11 }
            ]
        },
        {
            questionTitle: 'Você vai precisar também de <mark>vídeos</mark> ?',
            answerOptions: [
                { answerText: 'Sim', icon: proximoMinhaLoja },
                { answerText: 'Por enquanto não', icon: cidadeInteria },
                { answerText: 'Sim, mas vou utilizar somente os que eu gravar', icon: estadoInteiro },
                { answerText: 'Não sei', icon: brasilTodo }
            ]
        },
        {
            questionTitle: 'Você vai precisar de <mark>interação com os seguidores nos posts</mark> ?',
            answerOptions: [
                { answerText: 'Sim', icon: somentoHorarioComercial },
                { answerText: 'Não', icon: tempoTodo },
                { answerText: 'Sim, mas eu mesmo vou interagir', icon: emPromocoes },
                { answerText: 'Não sei', icon: naoSeiGostariaDeInformacoes13 }
            ]
        },
        {
            questionTitle: 'Qual <mark>tipo de conteúdo</mark> você gostaria que fosse postado?',
            answerOptions: [
                { answerText: 'Meu portfólio de produtos ou serviços', icon: apenasUm },
                { answerText: 'Carrosséis com dicas e informações', icon: de2a5 },
                { answerText: 'Apenas minhas fotos ou vídeos', icon: de5a10 },
                { answerText: 'Um mix de tudo', icon: maisDe10 }
            ]
        },
        {
            questionTitle: 'Você gostaria de ter um <mark>fotógrafo profissional</mark> para fazer fotos dos seus produtos ou de sua loja?',
            answerOptions: [
                { answerText: 'Sim', icon: atravesDeFormulario },
                { answerText: 'Não', icon: atravesLigacoes },
                { answerText: 'Minhas fotos são de boa qualidade', icon: atravesDeDM },
                { answerText: 'Não sei, gostaria de mais informações', icon: naoSeiGostariaDeInformacoes15 }
            ]
        },
        {
            questionTitle: 'Quais dessas <mark>métricas</mark> você acha mais importante?',
            answerOptions: [
                { answerText: 'Número de seguidores', icon: euMesmo },
                { answerText: 'Conversas iniciadas', icon: alguemDaMinhaEquipe },
                { answerText: 'Engajamento nos posts(likes e comentários)', icon: precisoDeUmaConsultoria },
                { answerText: 'Não sei', icon: queroColocarChatbots }
            ]
        },
        {
            questionTitle: 'Você já fez <mark>anúncios pagos</mark> nas redes sociais?',
            answerOptions: [
                { answerText: 'Sim, impulsionei posts', icon: sim17 },
                { answerText: 'Sim, já possuo uma conta de anúncios', icon: nao17 },
                { answerText: 'Não, mas pretendo', icon: simNaoTiveResultados },
                { answerText: 'Não, e por enquanto não pretendo', icon: naoMasPretendo }
            ]
        },
        {
            questionTitle: 'Você vai precisar criar uma <mark>loja no Instagram</mark> ?',
            answerOptions: [
                { answerText: 'Sim', icon: relatorioBasico },
                { answerText: 'Não', icon: relatorioPersonalidado },
                { answerText: 'Já tenho criada, só preciso profissionalizá-la', icon: naoPrecisoDeRelatorio },
                { answerText: 'Não sei, gostaria de mais informações', icon: naoSeiGostariaDeInformacoes }
            ]
        },
        {
            questionTitle: '',
            answerOptions: []
        },
        {
            questionTitle: 'Para quando você precisa iniciar a Gestão das Redes Sociais?',
            answerOptions: [
                { answerText: 'Imediatamente', icon: imediatamente },
                { answerText: 'Em até 1 semana', icon: emAte1Semana },
                { answerText: 'Para as próximas semanas', icon: proximasSemanas },
                { answerText: 'Em 1 mês, no máximo', icon: maximo1Mes }
            ]
        },
    ]
    return (
        <>
            <Header>
                <header>
                    {positonPath < 21 &&
                        <button type="button" onClick={handleBackPositionPath}>
                            <img src={arrowLeft} alt="" />
                            <span>voltar</span>
                        </button>
                    }

                    {positonPath < 20 &&
                        <h2>{positonPath + 1}/20</h2>
                    }
                </header>
            </Header>

            {positonPath !== 18 && positonPath <= 19 &&
                <>
                <ContentHeader>
                    <h1 dangerouslySetInnerHTML={{ __html: questions[positonPath].questionTitle }} ></h1>
                </ContentHeader>
                <Container>
                    <Content>
                        <AnimateSharedLayout>
                            {questions[positonPath].answerOptions.map((answerOption) => (
                                <motion.div variants={containerTransation} initial="hidden" animate="show" key={positonPath + answerOption.answerText} className="ItemOption" onClick={() => handleNextPositionPath(questions[positonPath].questionTitle, answerOption.answerText, positonPath)}>
                                    <div className="CircleBox">
                                        <img src={answerOption.icon} alt="" />
                                    </div>
                                    <p>{answerOption.answerText}</p>
                                </motion.div>
                            ))}
                        </AnimateSharedLayout>
                    </Content>
                </Container>
            </>
            }

            {positonPath === 18 &&
                <Container>
                    <div className='header'>
                        <h1>Você possui referências de <mark>outras marcas</mark> que gostaria de <mark>se inspirar</mark> ?</h1>
                        <h3>Se não tiver apenas deixe em branco e clique em 'avançar'</h3>
                    </div>

                    <div className="SelectOption">
                        <p>Referência 1</p>
                        <input list="reference1" name='reference1' value={references.reference1} onChange={handleChange} placeholder="Cole aqui a @ do Instagram de referência" />

                    </div>
                    <div className="SelectOption">
                        <p>Referência 2</p>
                        <input list="reference2" name='reference2' value={references.reference2} onChange={handleChange} placeholder="Cole aqui a @ do Instagram de referência" />
                        <button className="manualButton" type="button" onClick={() => handleNextPositionPath("Você possui referências de outras marcas que gostaria de se inspirar?", references, positonPath)}> Avançar</button>
                    </div>
                </Container>
            }

            {positonPath === 20 &&
                <>
                <ContentHeader>
                    <h1>Para finalizar, gostariamos de o conhecer melhor.</h1>
                </ContentHeader>
                <Container>
                    <ContainerForm onSubmit={handleSendBrifing}>
                        <div className="inputText">
                            <div>
                                <input type="text" placeholder="Nome" value={name} onChange={event => setName(event.target.value)} />
                                {errors.name && <p>{errors.name}</p>}
                            </div>
                            <div>
                                <input type="email" placeholder="Email" value={email} onChange={event => setEmail(event.target.value)} />
                                {errors.email && <p>{errors.email}</p>}
                            </div>
                            <input type="tel" placeholder="Telefone" value={phone} onChange={event => setPhone(event.target.value)} />
                            <input type="text" placeholder="Nome da Empresa" value={companyName} onChange={event => setCompanyName(event.target.value)} />
                            <input type="text" placeholder="Cupom" value={cupomName} onChange={event => setCupomName(event.target.value)} />
                        </div>
                        <CompanyTypeContainer>
                            <RadioBox
                                type="button"
                                onClick={() => { setType('individual') }}
                                isActive={type === 'individual'}
                                activeColor="green"
                            >
                                <img src={individual} alt="Individual" />
                                <span>Sou um Particular</span>
                            </RadioBox>

                            <RadioBox
                                type="button"
                                onClick={() => { setType('startup') }}
                                isActive={type === 'startup'}
                                activeColor="green"
                            >
                                <img src={startup} alt="Startup" />
                                <span>Startup</span>
                            </RadioBox>

                            <RadioBox
                                type="button"
                                onClick={() => { setType('sme') }}
                                isActive={type === 'sme'}
                                activeColor="green"
                            >
                                <img src={sme} alt="PME" />
                                <span>PME</span>
                            </RadioBox>

                            <RadioBox
                                type="button"
                                onClick={() => { setType('agency') }}
                                isActive={type === 'agency'}
                                activeColor="green"
                            >
                                <img src={agency} alt="Individual" />
                                <span>Agência</span>
                            </RadioBox>

                            <RadioBox
                                type="button"
                                onClick={() => { setType('large_enterprise') }}
                                isActive={type === 'large_enterprise'}
                                activeColor="green"
                            >
                                <img src={large_enterprise} alt="Individual" />
                                <span>Grande Empresa</span>
                            </RadioBox>

                        </CompanyTypeContainer>

                        <button type="submit" disabled={isLoading}>{isLoading && <i className="fa fa-spinner fa-spin"></i>} Finalizar</button>

                    </ContainerForm>
                </Container>
            </>
            }

            {positonPath === 21 &&
                <Container>
                    <motion.div
                        className="confirmIcon"
                        variants={containerTransation}
                        initial="hidden"
                        animate="show"
                    >
                        <img src={confirm} alt="Obrigado" />
                        <h1>Obrigado pelo seu Tempo</h1>
                        <p>Um e-mail foi enviado pra nossa equipe, e entraremos em contanto o quanto antes!</p>
                        <button className="manualButton" type="button" onClick={() => { onSelectPath('main') }}>Comece um briefing de outros produtos</button>
                    </motion.div>
                </Container>
            }

            <Footer>
                <a href="https://www.brutalit.com.br"><p>Powered by Brutalit</p></a>
            </Footer>
        </>
    )
}