import otimaQualidade from '../../assets/Options/otimaQualidade.svg'
import boaRelacao from '../../assets/Options/boaRelacao.svg'
import qualidadeNaoePrioridade from '../../assets/Options/qualidadeNaoePrioridade.svg'

import sim2 from '../../assets/CriacoECommerce/Option_2/sim.svg'
import naoJaTenhoExperiencia from '../../assets/CriacoECommerce/Option_2/naoJaTenhoExperiencia.svg'
import naoMasNaoTenhoExperiencia from '../../assets/CriacoECommerce/Option_2/naoMasNaoTenhoExperiencia.svg'
import contrateiOutrasPessoas from '../../assets/CriacoECommerce/Option_2/contrateiOutrasPessoas.svg'

import android from '../../assets/DesenvolvimenteApp/Option_3/android.svg'
import iOS from '../../assets/DesenvolvimenteApp/Option_3/iOS.svg'
import androidiOS from '../../assets/DesenvolvimenteApp/Option_3/androidiOS.svg'

import appGratuito from '../../assets/DesenvolvimenteApp/Option_6/appGratuito.svg'
import appPagoPorAssinatura from '../../assets/DesenvolvimenteApp/Option_6/appPagoPorAssinatura.svg'
import comprasDentroDoApp from '../../assets/DesenvolvimenteApp/Option_6/comprasDentroDoApp.svg'

import emailCelular from '../../assets/DesenvolvimenteApp/Option_7/emailCelular.svg'
import emailFacebookGoogle from '../../assets/DesenvolvimenteApp/Option_7/emailFacebookGoogle.svg'
import emailFacebookGoogleCelular from '../../assets/DesenvolvimenteApp/Option_7/emailFacebookGoogleCelular.svg'

import peloMenosUmNivel from '../../assets/DesenvolvimenteApp/Option_10/peloMenosUmNivel.svg'
import peloMenosDoisNiveis from '../../assets/DesenvolvimenteApp/Option_10/peloMenosDoisNiveis.svg'

import umIdioma from '../../assets/DesenvolvimenteApp/Option_11/umIdioma.svg'
import doisIdiomas from '../../assets/DesenvolvimenteApp/Option_11/doisIdiomas.svg'
import variosIdiomas from '../../assets/DesenvolvimenteApp/Option_11/variosIdiomas.svg'

import apenasUmaIdeia from '../../assets/DesenvolvimenteApp/Option_12/apenasUmaIdeia.svg'
import tenhoUmEsboco from '../../assets/DesenvolvimenteApp/Option_12/tenhoUmEsboco.svg'
import jaEstaEmDesenvolvimento from '../../assets/DesenvolvimenteApp/Option_12/jaEstaEmDesenvolvimento.svg'

import umaParte from '../../assets/DesenvolvimenteApp/Option_16/umaParte.svg'
import duasPartes from '../../assets/DesenvolvimenteApp/Option_16/duasPartes.svg'
import tresPartes from '../../assets/DesenvolvimenteApp/Option_16/tresPartes.svg'


import interfaceSimples from '../../assets/CriacoECommerce/Option_7/interfaceSimples.svg'
import interfacePersonalizada from '../../assets/CriacoECommerce/Option_7/interfacePersonalizada.svg'
import jaTenhoInterface from '../../assets/CriacoECommerce/Option_7/jaTenhoInterface.svg'
import naoPenseiDireito from '../../assets/CriacoECommerce/Option_7/naoPenseiDireito.svg'

import aindaNaoPensei from '../../assets/CriacoECommerce/Option_13/aindaNaoPensei.svg'

import queroTerceirizar from '../../assets/CriacoECommerce/Option_12/queroTerceirizar.svg'
import queroPublicar from '../../assets/CriacoECommerce/Option_13/queroPublicar.svg'
import gostariaDeEntender from '../../assets/CriacoECommerce/Option_18/gostariaDeEntender.svg'
import masParaFrente from '../../assets/CriacoECommerce/Option_18/masParaFrente.svg'

import sim6 from '../../assets/GoogleAds/Options_6/sim.svg'
import naoSeiGostaria from '../../assets/GoogleAds/Options_6/naoSeiGostaria.svg'
import nao7 from '../../assets/GoogleAds/Options_7/nao.svg'
import euMesmo from '../../assets/GoogleAds/Options_16/euMesmo.svg'

import imediatamente from '../../assets/GoogleAds/Options_20/imediatamente.svg'
import emAte1Semana from '../../assets/GoogleAds/Options_20/emAte1Semana.svg'
import proximasSemanas from '../../assets/GoogleAds/Options_20/proximasSemanas.svg'
import maximo1Mes from '../../assets/GoogleAds/Options_20/1mes.svg'

import individual from '../../assets/form/individual.svg'
import startup from '../../assets/form/startup.svg'
import sme from '../../assets/form/sme.svg'
import agency from '../../assets/form/agency.svg'
import large_enterprise from '../../assets/form/large_enterprise.svg'
import confirm from '../../assets/form/confirm.svg'

import arrowLeft from '../../assets/arrow-left.svg'

import { Container, Content, Footer, ContainerForm, Header, CompanyTypeContainer, RadioBox, ContentHeader } from "../../styles/questionsStyles"
import { FormEvent, useEffect, useState } from 'react'
import { api, ip } from '../../services/api';

import { AnimateSharedLayout, motion } from 'framer-motion';
import SelectInput from '../SelectInput'
import ReferenceInput from '../ReferenceInput'

interface PathSelectionProps {
    onSelectPath: (selectPath: string) => void;
}

interface ErrorProps {
    name: string,
    email: string
}

export function DesenvolvimentoApp({ onSelectPath }: PathSelectionProps) {

    const [type, setType] = useState('')
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [cupomName, setCupomName] = useState('');

    const [errors, setErrors] = useState<ErrorProps>({
        name: '',
        email: ''
    })

    const [positonPath, setPositionPath] = useState(0)
    const [choice, setChoices] = useState<Object[]>([])
    const [references, setReferences] = useState({ reference1: '', reference2: '' })
    const [segment, setSegment] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const containerTransation = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    }

    console.log(choice)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setReferences(refer => ({
            ...refer,
            [name]: value
        }));
    }

    async function handleExit() {
        const userIP = await ip.get("").then(response => {
            return {
                "Region": response.data.region,
                "IP": response.data.query
            }
        }).catch(() => {
            console.log('Request failed');
        })

        const choices = {
            choices: choice,
            userData: userIP
        }
        const response = await api.post('/abandonedBriefing', choices)
        console.log(response)
    };

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    })

    const alertUser = (event: any) => {
        event.preventDefault();
        event.returnValue = '';
        handleExit();
    }

    function handleNextPositionPath(question: string, choiceSelected: string | object, index: number) {
        if (positonPath > 20) return

        question = question.replace("<mark>", "").replace("</mark>", "")

        const choiceResponce = { "question": question, "answer": choiceSelected }

        const arrayChoice = [...choice]
        arrayChoice[index] = choiceResponce
        setChoices(arrayChoice)

        setPositionPath(positonPath + 1)
        window.scrollTo(0, 0)
    }

    async function handleSendBrifing(event: FormEvent) {
        event.preventDefault();
        setIsLoading(true)

        const isFormValid = validateForm();

        if (isFormValid) {

            const userIP = await ip.get("").then(response => {
                return {
                    "Região": response.data.region,
                    "IP": response.data.query
                }
            }).catch(() => {
                console.log('Request failed');
            })

            const newBriefing = {
                name: name,
                email: email,
                phone: phone,
                companyName: companyName,
                type: type,
                choices: choice,
                briefingType: "Desenvolvimento de App",
                cupomName: cupomName,
                UserDataIP: userIP
            }


            const response = await api.post('/briefing', newBriefing)
            setIsLoading(false)

            if (response) {
                setPositionPath(positonPath + 1)
            }

        } else {
            window.scrollTo(0, 0)
            setIsLoading(false)
        }
    }

    function validateForm() {
        let errors = {
            name: '',
            email: ''
        };
        let isValid = true;

        if (!name.trim()) {
            errors.name = "Campo nome obrigatorio";
            isValid = false;
        }

        if (!email) {
            errors.email = "Email obrigatorio"
            isValid = false;
        } else if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            errors.email = "Email invalido"
            isValid = false;
        }

        setErrors(errors)
        return isValid;
    }

    function handleBackPositionPath() {
        if (positonPath <= 0) onSelectPath('main')

        setPositionPath(positonPath - 1)
    }

    const questions = [
        {
            questionTitle: 'Qual <mark>nível de qualidade</mark> você está procurando?',
            answerOptions: [
                { answerText: 'Ótima qualidade', icon: otimaQualidade },
                { answerText: 'Boa relação entre qualidade e preço', icon: boaRelacao },
                { answerText: 'Qualidade não é prioridade', icon: qualidadeNaoePrioridade }
            ]
        },
        {
            questionTitle: 'É o seu <mark>primeiro</mark> app?',
            answerOptions: [
                { answerText: 'Sim', icon: sim2 },
                { answerText: 'Não, já tenho experiência', icon: naoJaTenhoExperiencia },
                { answerText: 'Não, mas não tenho experiência', icon: naoMasNaoTenhoExperiencia },
                { answerText: 'Contratei outras pessoas mas não saiu como eu esperava', icon: contrateiOutrasPessoas }
            ]
        },
        {
            questionTitle: 'Em quais <mark>plataformas</mark> você quer seu app?',
            answerOptions: [
                { answerText: 'Android', icon: android },
                { answerText: 'iOS', icon: iOS },
                { answerText: 'Android e iOS', icon: androidiOS },
                { answerText: 'Não sei ainda, gostaria de mais orientações', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: '',
            answerOptions: []
        },
        {
            questionTitle: 'Qual <mark>tipo de interface</mark> você deseja no app?',
            answerOptions: [
                { answerText: 'Interface simples', icon: interfaceSimples },
                { answerText: 'Interface personalizada', icon: interfacePersonalizada },
                { answerText: 'Já tenho uma interface pronta.', icon: jaTenhoInterface },
                { answerText: 'Interface semelhante à Web', icon: naoPenseiDireito }
            ]
        },
        {
            questionTitle: 'Como você prentende <mark>monetizar</mark> seu app?',
            answerOptions: [
                { answerText: 'App gratuito com publicidade', icon: appGratuito },
                { answerText: 'App pago por assinatura', icon: appPagoPorAssinatura },
                { answerText: 'Compras dentro do app', icon: comprasDentroDoApp },
                { answerText: 'Ainda não pensei à respeito', icon: aindaNaoPensei }
            ]
        },
        {
            questionTitle: 'Qual o tipo de <mark>sistema de login</mark> você gostaria em seu app?',
            answerOptions: [
                { answerText: 'Apenas e-mail ou número de celular', icon: emailCelular },
                { answerText: 'E-mail, Facebook e Google', icon: emailFacebookGoogle },
                { answerText: 'E-mail, Facebook, Google e número de celular', icon: emailFacebookGoogleCelular },
                { answerText: 'Não sei ainda, gostaria de orientações', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Os usuários do app deve, ter seus <mark>perfis próprios</mark>?',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não', icon: nao7 },
                { answerText: 'Não sei ainda, gostaria de orientações', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Seu app precisa ser <mark>integrado a um website</mark>?',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não', icon: nao7 },
                { answerText: 'Não sei ainda, gostaria de orientações', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Seu app vai precisar de um <mark>dashboard</mark>?',
            answerOptions: [
                { answerText: 'Sim, com pelo menos 1 nível de acesso', icon: peloMenosUmNivel },
                { answerText: 'Sim, com pelo menos 2 níveis ou mais de acesso', icon: peloMenosDoisNiveis },
                { answerText: 'Não', icon: nao7 },
                { answerText: 'Não sei ainda, gostaria de orientações', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Quantos <mark>idiomas</mark> você deseja no app?',
            answerOptions: [
                { answerText: 'Um único idioma', icon: umIdioma },
                { answerText: 'Bilíngue', icon: doisIdiomas },
                { answerText: 'Multilíngue', icon: variosIdiomas },
                { answerText: 'Não decidi ainda, gostaria de ajuda', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Em <mark>qual estágio</mark> se encontra o seu projeto?',
            answerOptions: [
                { answerText: 'É apenas uma ideia, por enquanto', icon: apenasUmaIdeia },
                { answerText: 'Já tenho um esboço preparado', icon: tenhoUmEsboco },
                { answerText: 'Já está em desenvolvimento, quero trocar a equipe', icon: jaEstaEmDesenvolvimento },
                { answerText: 'Não sei', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Os usuários deverão <mark>avaliar ou deixar comentários</mark> dentro do app?',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não', icon: nao7 },
                { answerText: 'Não sei ainda, gostaria de orientações', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Você vai precisar criar uma <mark>identidade visual</mark> (incluindo o ícone) para o app?',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não, já tenho tudo pronto', icon: nao7 },
                { answerText: 'Sim, mas gostaria de mais informações', icon: gostariaDeEntender },
                { answerText: 'Não sei do que se trata', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Você já possui a <mark>regra de negócios</mark> pronta?',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não', icon: nao7 },
                { answerText: 'Não, e gostaria de terceirizá-la também', icon: queroTerceirizar },
                { answerText: 'Não sei do que se trata', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Além de você como administrador, <mark>quantas partes</mark> estarão envolvidas no app?',
            questionSubtitle: 'Ex: IFood (Restaurante, Cliente, Entragador = 3 partes)',
            answerOptions: [
                { answerText: 'Somente uma parte', icon: umaParte },
                { answerText: 'Duas partes', icon: duasPartes },
                { answerText: 'Três partes', icon: tresPartes },
                { answerText: 'Não sei ainda', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Você pretende utilizar ferramentas de automação em seu app(e-mail marketing, integrações de estoque etc)?',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não', icon: nao7 },
                { answerText: 'Gostaria de entender mais à respeito', icon: gostariaDeEntender },
                { answerText: 'Mais pra frente', icon: masParaFrente }
            ]
        },
        {
            questionTitle: 'Qual nível de autonomia você gostaria de ter para a <mark>manutenção do app após a entrega</mark>?',
            answerOptions: [
                { answerText: 'Vou montar minha própria equipe para isso', icon: euMesmo },
                { answerText: 'Quero terceirizar a manutenção', icon: queroTerceirizar },
                { answerText: 'Quero apenas publicar, deixar no ar e não pensar mais nisso', icon: queroPublicar },
                { answerText: 'Ainda não pensei sobre isso', icon: aindaNaoPensei }
            ]
        },
        {
            questionTitle: '',
            answerOptions: []
        },
        {
            questionTitle: 'Para quando você precisa do e-commerce/loja virtual no ar?',
            answerOptions: [
                { answerText: 'Imediatamente', icon: imediatamente },
                { answerText: 'Em até 2 semanas', icon: emAte1Semana },
                { answerText: 'Em até 1 mês, no máximo', icon: proximasSemanas },
                { answerText: 'Para os próximos meses', icon: maximo1Mes }
            ]
        }
    ]

    return (
        <>
            <Header>
                <header>
                    {positonPath < 21 &&
                        <button type="button" onClick={handleBackPositionPath}>
                            <img src={arrowLeft} alt="" />
                            <span>voltar</span>
                        </button>
                    }

                    {positonPath < 20 &&
                        <h2>{positonPath + 1}/20</h2>
                    }
                </header>
            </Header>


            {positonPath !== 3 && positonPath !== 18 && positonPath <= 19 &&
                <>
                    <ContentHeader>
                        <h1 dangerouslySetInnerHTML={{ __html: questions[positonPath].questionTitle }} ></h1>
                        <h2>{questions[positonPath].questionSubtitle}</h2>
                    </ContentHeader>
                    <Container>
                        <Content>
                            <AnimateSharedLayout>
                                {questions[positonPath].answerOptions.map((answerOption) => (
                                    <motion.div variants={containerTransation} initial="hidden" animate="show" key={positonPath + answerOption.answerText} className="ItemOption" onClick={() => handleNextPositionPath(questions[positonPath].questionTitle, answerOption.answerText, positonPath)}>
                                        <div className="CircleBox">
                                            <img src={answerOption.icon} alt="" />
                                        </div>
                                        <p>{answerOption.answerText}</p>
                                    </motion.div>
                                ))}
                            </AnimateSharedLayout>
                        </Content>
                    </Container>
                </>
            }

            {positonPath === 3 &&
                <SelectInput
                    title={"Selecione o <mark>tipo de app</mark> que você gostaria de criar:"}
                    handleNextPositionPath={handleNextPositionPath}
                    positonPath={positonPath}
                    segment={segment}
                    setSegment={setSegment}
                />
            }

            {positonPath === 18 &&
                <ReferenceInput
                    title={"Você já possui algum app que gostaria de usar como <mark>referência</mark>?"}
                    handleNextPositionPath={handleNextPositionPath}
                    positonPath={positonPath}
                    references={references}
                    handleChange={handleChange}
                    titleBox1={"Nome do app 1"}
                    titleBox2={"Nome do app 2"}
                    placeholder={"Escreva aqui o nome do seu app de referência"}
                />
            }


            {positonPath === 20 &&
                <>
                    <ContentHeader>
                        <h1>Para finalizar, gostariamos de o conhecer melhor.</h1>
                    </ContentHeader>
                    <Container>
                        <ContainerForm onSubmit={handleSendBrifing}>
                            <div className="inputText">
                                <div>
                                    <input type="text" placeholder="Nome" value={name} onChange={event => setName(event.target.value)} />
                                    {errors.name && <p>{errors.name}</p>}
                                </div>
                                <div>
                                    <input type="email" placeholder="Email" value={email} onChange={event => setEmail(event.target.value)} />
                                    {errors.email && <p>{errors.email}</p>}
                                </div>
                                <input type="tel" placeholder="Telefone" value={phone} onChange={event => setPhone(event.target.value)} />
                                <input type="text" placeholder="Nome da Empresa" value={companyName} onChange={event => setCompanyName(event.target.value)} />
                                <input type="text" placeholder="Cupom" value={cupomName} onChange={event => setCupomName(event.target.value)} />
                            </div>
                            <CompanyTypeContainer>
                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('individual') }}
                                    isActive={type === 'individual'}
                                    activeColor="green"
                                >
                                    <img src={individual} alt="Individual" />
                                    <span>Sou um Particular</span>
                                </RadioBox>

                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('startup') }}
                                    isActive={type === 'startup'}
                                    activeColor="green"
                                >
                                    <img src={startup} alt="Startup" />
                                    <span>Startup</span>
                                </RadioBox>

                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('sme') }}
                                    isActive={type === 'sme'}
                                    activeColor="green"
                                >
                                    <img src={sme} alt="PME" />
                                    <span>PME</span>
                                </RadioBox>

                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('agency') }}
                                    isActive={type === 'agency'}
                                    activeColor="green"
                                >
                                    <img src={agency} alt="Individual" />
                                    <span>Agência</span>
                                </RadioBox>

                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('large_enterprise') }}
                                    isActive={type === 'large_enterprise'}
                                    activeColor="green"
                                >
                                    <img src={large_enterprise} alt="Individual" />
                                    <span>Grande Empresa</span>
                                </RadioBox>

                            </CompanyTypeContainer>

                            <button type="submit" disabled={isLoading}>{isLoading && <i className="fa fa-spinner fa-spin"></i>} Finalizar</button>

                        </ContainerForm>
                    </Container>
                </>
            }
            {positonPath === 21 &&
                <Container>
                    <motion.div
                        className="confirmIcon"
                        variants={containerTransation}
                        initial="hidden"
                        animate="show"
                    >
                        <img src={confirm} alt="Obrigado" />
                        <h1>Parabéns, você deu um importante passo.</h1>
                        <p>Um especialista da Brutalit entrará em contato com você o quanto antes!</p>
                        <button className="manualButton" type="button" onClick={() => { onSelectPath('main') }}>Comece um briefing de outros produtos</button>
                    </motion.div>


                </Container>
            }


            <Footer>
                <a href="https://www.brutalit.com.br"><p>Powered by Brutalit</p></a>
            </Footer>
        </>
    )
}