import anunciarNoGoogle from '../../assets/main/anunciarNoGoogle.svg'
import anunciosNasRedesSociais from '../../assets/main/anunciosNasRedesSociais.svg'
import automacoes from '../../assets/main/automacoes.svg'
import criacaoDeEcommerce from '../../assets/main/criacaoDeEcommerce.svg'
import criacaoDeSites from '../../assets/main/criacaoDeSites.svg'
import desenvolvimentDeApp from '../../assets/main/desenvolvimentDeApp.svg'
import gestaoDeRedesSociais from '../../assets/main/gestaoDeRedesSociais.svg'
import identidadeVisual from '../../assets/main/identidadeVisual.svg'

import { Container, Content, Footer, Header } from "./styles"

// import { Link } from "react-router-dom";

interface PathSelectionProps {
    onSelectPath: (selectPath: string) => void;
}

export function BriefingMain({ onSelectPath }: PathSelectionProps) {
    return (
        <>
            <Container>
                <Header>
                    <h1><mark>Selecione os produtos de seu interesse</mark></h1>
                </Header>
                <Content>
                    <div className="ItemOption" onClick={() => onSelectPath('CriacaoDeWebsite')}>
                        <div className="CircleBox">
                            <img src={criacaoDeSites} alt="" />
                        </div>
                        <p>Criação de Sites</p>
                    </div>

                    <div className="ItemOption" onClick={() => onSelectPath('CriacaoDeEcommerce')}>
                        <div className="CircleBox">
                            <img src={criacaoDeEcommerce} alt="" />
                        </div>
                        <p>Criação de E-commerce</p>
                    </div>

                    <div className="ItemOption" onClick={() => onSelectPath('GestaoDeRedes')}>
                        <div className="CircleBox">
                            <img src={gestaoDeRedesSociais} alt="" />
                        </div>
                        <p>Gestão de Redes Sociais</p>
                    </div>

                    <div className="ItemOption" onClick={() => onSelectPath('AnunciosNasRedesSociais')}>
                        <div className="CircleBox">
                            <img src={anunciosNasRedesSociais} alt="" />
                        </div>
                        <p>Anúncios nas Redes Sociais</p>
                    </div>

                    <div className="ItemOption" onClick={() => onSelectPath('GoogleAds')}>
                        {/* <Link to="/GoogleAds"> */}
                            <div className="CircleBox">
                                <img src={anunciarNoGoogle} alt="" />
                            </div>
                            <p>Anunciar no Google</p>
                        {/* </Link> */}
                    </div>

                    <div className="ItemOption">
                        <div className="CircleBox">
                            <img src={identidadeVisual} alt="" />
                        </div>
                        <p>Identidade Visual</p>
                    </div>

                    <div className="ItemOption">
                        <div className="CircleBox">
                            <img src={automacoes} alt="" />
                        </div>
                        <p>Automações</p>
                    </div>

                    <div className="ItemOption" onClick={() => onSelectPath('DesenvolvimentoApp')}>
                        <div className="CircleBox">
                            <img src={desenvolvimentDeApp} alt="" />
                        </div>
                        <p>Desenvolvimento de App</p>
                    </div>
                </Content>
                <Footer>
                    <div>
                        <a href="https://www.brutalit.com.br">
                            <p>Powered by Brutalit</p>
                        </a>
                    </div>
                </Footer>
            </Container>
        </>
    )
}