import otimaQualidade from '../../assets/CriacoECommerce/Option_1/otimaQualidade.svg'
import boaRelacao from '../../assets/CriacoECommerce/Option_1/boaRelacao.svg'
import qualidadeNaoePrioridade from '../../assets/CriacoECommerce/Option_1/qualidadeNaoePrioridade.svg'

import sim2 from '../../assets/CriacoECommerce/Option_2/sim.svg'
import naoJaTenhoExperiencia from '../../assets/CriacoECommerce/Option_2/naoJaTenhoExperiencia.svg'
import naoMasNaoTenhoExperiencia from '../../assets/CriacoECommerce/Option_2/naoMasNaoTenhoExperiencia.svg'
import contrateiOutrasPessoas from '../../assets/CriacoECommerce/Option_2/contrateiOutrasPessoas.svg'

import Grupo152 from '../../assets/CriacoECommerce/Option_3/Grupo 152.svg'
import Grupo154 from '../../assets/CriacoECommerce/Option_3/Grupo 154.svg'
import Grupo158 from '../../assets/CriacoECommerce/Option_3/Grupo 158.svg'
import Grupo235 from '../../assets/CriacoECommerce/Option_3/Grupo 235.svg'

import sim4 from '../../assets/CriacoECommerce/Option_4/sim.svg'
import nao4 from '../../assets/CriacoECommerce/Option_4/nao.svg'
import simMasPerdio from '../../assets/CriacoECommerce/Option_4/simMasPerdio.svg'
import naoEGostaria from '../../assets/CriacoECommerce/Option_4/naoEGostaria.svg'

import sim5 from '../../assets/CriacoECommerce/Option_5/sim.svg'
import simMasTerceiros from '../../assets/CriacoECommerce/Option_5/simMasTerceiros.svg'
import nao5 from '../../assets/CriacoECommerce/Option_5/nao.svg'
import naoEnaoSei from '../../assets/CriacoECommerce/Option_5/naoEnaoSei.svg'

import wordpress from '../../assets/CriacoECommerce/Option_6/wordpress.svg'
import htmlCssJavaScript from '../../assets/CriacoECommerce/Option_6/htmlCssJavaScript.svg'
import naoSeiGostariaOrientacao from '../../assets/CriacoECommerce/Option_6/naoSeiGostariaOrientacao.svg'
import semPreferencia from '../../assets/CriacoECommerce/Option_6/semPreferencia.svg'

import interfaceSimples from '../../assets/CriacoECommerce/Option_7/interfaceSimples.svg'
import interfacePersonalizada from '../../assets/CriacoECommerce/Option_7/interfacePersonalizada.svg'
import jaTenhoInterface from '../../assets/CriacoECommerce/Option_7/jaTenhoInterface.svg'
import naoPenseiDireito from '../../assets/CriacoECommerce/Option_7/naoPenseiDireito.svg'

import facebookGoolge from '../../assets/CriacoECommerce/Option_8/facebookGoolge.svg'
import apenasEmail from '../../assets/CriacoECommerce/Option_8/apenasEmail.svg'
import naoSeiAinda8 from '../../assets/CriacoECommerce/Option_8/naoSeiAinda.svg'

import simJaEstaTudoSeparado from '../../assets/CriacoECommerce/Option_9/simJaEstaTudoSeparado.svg'
import naoTenhoInformacoes from '../../assets/CriacoECommerce/Option_9/naoTenhoInformacoes.svg'
import naoPrecisoQueMeAjude from '../../assets/CriacoECommerce/Option_9/naoPrecisoQueMeAjude.svg'
import naoSeiDoQueTrata from '../../assets/CriacoECommerce/Option_9/naoSeiDoQueTrata.svg'

import umxsemana from '../../assets/CriacoECommerce/Option_11/1xsemana.svg'
import umxCada15dias from '../../assets/CriacoECommerce/Option_11/1xCada15dias.svg'
import umxPorMes from '../../assets/CriacoECommerce/Option_11/1xPorMes.svg'

import queroTerceirizar from '../../assets/CriacoECommerce/Option_12/queroTerceirizar.svg'
import aindaNaoPensei from '../../assets/CriacoECommerce/Option_13/aindaNaoPensei.svg'

import queroPublicar from '../../assets/CriacoECommerce/Option_13/queroPublicar.svg'

import cartaoDeCredito from '../../assets/CriacoECommerce/Option_14/cartaoDeCredito.svg'
import cartaoBoleto from '../../assets/CriacoECommerce/Option_14/cartaoBoleto.svg'
import cartaoDebitoBoleto from '../../assets/CriacoECommerce/Option_14/cartaoDebitoBoleto.svg'
import bitCoin from '../../assets/CriacoECommerce/Option_14/bitCoin.svg'

import euMesmoDespacho from '../../assets/CriacoECommerce/Option_15/euMesmoDespacho.svg'
import queroTerceirizaJaDefini from '../../assets/CriacoECommerce/Option_15/queroTerceirizaJaDefini.svg.svg'
import queroTerceirizarMasNaoDefini from '../../assets/CriacoECommerce/Option_15/queroTerceirizarMasNaoDefini.svg'

import gostariaDeEntender from '../../assets/CriacoECommerce/Option_18/gostariaDeEntender.svg'
import masParaFrente from '../../assets/CriacoECommerce/Option_18/masParaFrente.svg'

import sim6 from '../../assets/GoogleAds/Options_6/sim.svg'
import naoSeiGostaria from '../../assets/GoogleAds/Options_6/naoSeiGostaria.svg'

import nao7 from '../../assets/GoogleAds/Options_7/nao.svg'
import naoMasPretendo7 from '../../assets/GoogleAds/Options_7/naoMasPretendo.svg'
import naoSei from '../../assets/GoogleAds/Options_7/naoSei.svg'

import apenasUm from '../../assets/GoogleAds/Options_14/apenasUm.svg'
import de2a5 from '../../assets/GoogleAds/Options_14/de2a5.svg'
import de5a10 from '../../assets/GoogleAds/Options_14/de5a10.svg'
import maisDe10 from '../../assets/GoogleAds/Options_14/maisDe10.svg'

import euMesmo from '../../assets/GoogleAds/Options_16/euMesmo.svg'
import alguemDaMinhaEquipe from '../../assets/GoogleAds/Options_16/alguemDaMinhaEquipe.svg'

import imediatamente from '../../assets/GoogleAds/Options_20/imediatamente.svg'
import emAte1Semana from '../../assets/GoogleAds/Options_20/emAte1Semana.svg'
import proximasSemanas from '../../assets/GoogleAds/Options_20/proximasSemanas.svg'
import maximo1Mes from '../../assets/GoogleAds/Options_20/1mes.svg'

import individual from '../../assets/form/individual.svg'
import startup from '../../assets/form/startup.svg'
import sme from '../../assets/form/sme.svg'
import agency from '../../assets/form/agency.svg'
import large_enterprise from '../../assets/form/large_enterprise.svg'
import confirm from '../../assets/form/confirm.svg'

import arrowLeft from '../../assets/arrow-left.svg'

import { Container, Content, Footer, ContainerForm, Header, CompanyTypeContainer, RadioBox, ContentHeader } from "../../styles/questionsStyles"
import { FormEvent, useEffect, useState } from 'react'
import { api, ip } from '../../services/api';

import { AnimateSharedLayout, motion } from 'framer-motion';

interface PathSelectionProps {
    onSelectPath: (selectPath: string) => void;
}

interface ErrorProps {
    name: string,
    email: string
}

export function CriacaoDeEcommerce({ onSelectPath }: PathSelectionProps) {

    const [type, setType] = useState('')
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [cupomName, setCupomName] = useState('');

    const [errors, setErrors] = useState<ErrorProps>({
        name: '',
        email: ''
    })

    const [positonPath, setPositionPath] = useState(0)
    const [choice, setChoices] = useState<Object[]>([])
    const [references, setReferences] = useState({ reference1: '', reference2: '' })
    const [isLoading, setIsLoading] = useState(false);

    const containerTransation = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setReferences(refer => ({
            ...refer,
            [name]: value
        }));
    }

    async function handleExit() {
        const userIP = await ip.get("").then(response => {
            return {
                "Region": response.data.region,
                "IP": response.data.query
            }
        }).catch(() => {
            console.log('Request failed');
        })

        const choices = {
            choices: choice,
            userData: userIP
        }
        const response = await api.post('/abandonedBriefing', choices)
        console.log(response)
    };

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    })

    const alertUser = (event: any) => {
        event.preventDefault();
        event.returnValue = '';
        handleExit();
    }

    function handleNextPositionPath(question: string, choiceSelected: string | object, index: number) {
        if (positonPath > 20) return

        question = question.replace("<mark>", "").replace("</mark>", "")

        const choiceResponce = { "question": question, "answer": choiceSelected }

        const arrayChoice = [...choice]
        arrayChoice[index] = choiceResponce
        setChoices(arrayChoice)

        setPositionPath(positonPath + 1)
        window.scrollTo(0, 0)
    }

    async function handleSendBrifing(event: FormEvent) {
        event.preventDefault();
        setIsLoading(true)

        const isFormValid = validateForm();

        if (isFormValid) {

            const userIP = await ip.get("").then(response => {
                return {
                    "Região": response.data.region,
                    "IP": response.data.query
                }
            }).catch(() => {
                console.log('Request failed');
            })

            const newBriefing = {
                name: name,
                email: email,
                phone: phone,
                companyName: companyName,
                type: type,
                choices: choice,
                briefingType: "Criação de E-commerce",
                cupomName: cupomName,
                UserDataIP: userIP
            }


            const response = await api.post('/briefing', newBriefing)
            setIsLoading(false)

            if (response) {
                setPositionPath(positonPath + 1)
            }

        } else {
            window.scrollTo(0, 0)
            setIsLoading(false)
        }
    }

    function validateForm() {
        let errors = {
            name: '',
            email: ''
        };
        let isValid = true;

        if (!name.trim()) {
            errors.name = "Campo nome obrigatorio";
            isValid = false;
        }

        if (!email) {
            errors.email = "Email obrigatorio"
            isValid = false;
        } else if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            errors.email = "Email invalido"
            isValid = false;
        }

        setErrors(errors)
        return isValid;
    }

    function handleBackPositionPath() {
        if (positonPath <= 0) onSelectPath('main')

        setPositionPath(positonPath - 1)
    }

    const questions = [
        {
            questionTitle: 'Qual nível de qualidade você está procurando?',
            answerOptions: [
                { answerText: 'Ótima qualidade', icon: otimaQualidade },
                { answerText: 'Boa relação entre qualidade e preço', icon: boaRelacao },
                { answerText: 'Qualidade não é prioridade, preciso implementar logo', icon: qualidadeNaoePrioridade }
            ]
        },
        {
            questionTitle: 'É a sua primeira loja virtual/e-commerce?',
            answerOptions: [
                { answerText: 'Sim', icon: sim2 },
                { answerText: 'Não, já tenho experiência', icon: naoJaTenhoExperiencia },
                { answerText: 'Não, mas não tenho experiência', icon: naoMasNaoTenhoExperiencia },
                { answerText: 'Contratei outras pessoas mas não saiu como eu esperava', icon: contrateiOutrasPessoas }
            ]
        },
        {
            questionTitle: 'Você tem loja física?',
            answerOptions: [
                { answerText: 'Sim, uma única', icon: Grupo152 },
                { answerText: 'Sim, mais de uma unidade', icon: Grupo154 },
                { answerText: 'Não', icon: Grupo235 },
                { answerText: 'Não mais, mas já tive', icon: Grupo158 }
            ]
        },
        {
            questionTitle: 'Você já possui um domínio?',
            answerOptions: [
                { answerText: 'Sim', icon: sim4 },
                { answerText: 'Não', icon: nao4 },
                { answerText: 'Sim, mas perdi o acesso a ele', icon: simMasPerdio },
                { answerText: 'Não, e gostaria de ajuda para escolher', icon: naoEGostaria }
            ]
        },
        {
            questionTitle: 'Você já possui algum serviço de hospedagem?',
            answerOptions: [
                { answerText: 'Sim', icon: sim5 },
                { answerText: 'Sim, mas está com terceiros', icon: simMasTerceiros },
                { answerText: 'Não', icon: nao5 },
                { answerText: 'Não, e não sei do que se trata', icon: naoEnaoSei }
            ]
        },
        {
            questionTitle: 'Você tem preferência por algum tipo de tecnologia?',
            answerOptions: [
                { answerText: 'Wordpress / Woocommerce', icon: wordpress },
                { answerText: 'HTML, CSS e Javascript', icon: htmlCssJavaScript },
                { answerText: 'Não sei, gostaria de mais orientações.', icon: naoSeiGostariaOrientacao },
                { answerText: 'Sem preferência', icon: semPreferencia }
            ]
        },
        {
            questionTitle: 'Qual tipo de interface você deseja?',
            answerOptions: [
                { answerText: 'Interface simples', icon: interfaceSimples },
                { answerText: 'Interface personalizada', icon: interfacePersonalizada },
                { answerText: 'Já tenho uma interface pronta.', icon: jaTenhoInterface },
                { answerText: 'Não pensei à respeito.', icon: naoPenseiDireito }
            ]
        },
        {
            questionTitle: 'Qual sistema de login você prefere?',
            answerOptions: [
                { answerText: 'Com Facebook, Google e e-mail e telefone', icon: facebookGoolge },
                { answerText: 'Apenas com e-mail', icon: apenasEmail },
                { answerText: 'Não sei ainda', icon: naoSeiAinda8 },
                { answerText: 'Não tenho preferência', icon: naoPenseiDireito }
            ]
        },
        {
            questionTitle: 'Você já possui organizado o conteúdo a inserir no e-commerce',
            questionSubtitle: '(categorias, fotos, descrições dos produtos, textos sobre a loja, etc)?',
            answerOptions: [
                { answerText: 'Sim, já está tudo separado', icon: simJaEstaTudoSeparado },
                { answerText: 'Não, tenho as informações mas preciso organizá-las', icon: naoTenhoInformacoes },
                { answerText: 'Não, preciso que me ajudem a montar do zero', icon: naoPrecisoQueMeAjude },
                { answerText: 'Não sei do que se trata', icon: naoSeiDoQueTrata }
            ]
        },
        {
            questionTitle: 'Quantos produtos você tem para inserir no e-commerce?',
            answerOptions: [
                { answerText: '10 a 50 produtos', icon: apenasUm },
                { answerText: '51 a 200 produtos', icon: de2a5 },
                { answerText: '201 a 500 produtos ', icon: de5a10 },
                { answerText: 'Mais de 500 produtos', icon: maisDe10 }
            ]
        },
        {
            questionTitle: 'De quanto em quanto tempo você pretende adicionar novos produtos?',
            answerOptions: [
                { answerText: '1x por semana', icon: umxsemana },
                { answerText: '1x a cada 15 dias', icon: umxCada15dias },
                { answerText: '1x por mês', icon: umxPorMes },
                { answerText: 'Não sei ainda', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Qual nível de autonomia você gostaria de ter para inserção de produtos?',
            answerOptions: [
                { answerText: 'Eu mesmo gostaria de adicionar novos produtos', icon: euMesmo },
                { answerText: 'Alguém da minha equipe será responsável por isso', icon: alguemDaMinhaEquipe },
                { answerText: 'Gostaria também de terceirizar esse serviço', icon: queroTerceirizar },
                { answerText: 'Ainda não pensei sobre isso', icon: aindaNaoPensei }
            ]
        },
        {
            questionTitle: 'Qual nível de autonomia você gostaria de ter para a manutenção?',
            answerOptions: [
                { answerText: 'Eu mesmo gostaria de cuidar da manutenção.', icon: euMesmo },
                { answerText: 'Quero terceirizar a manutenção.', icon: queroTerceirizar },
                { answerText: 'Quero apenas publicar, deixar no ar e não pensar mais nisso.', icon: queroPublicar },
                { answerText: 'Ainda não pensei sobre isso.', icon: aindaNaoPensei }
            ]
        },
        {
            questionTitle: 'Quais meios de pagamento você pretende utilizar?',
            answerOptions: [
                { answerText: 'Cartão de Crédito', icon: cartaoDeCredito },
                { answerText: 'Cartão de Crédito e Boleto', icon: cartaoBoleto },
                { answerText: 'Cartão de Crédito, Débito e Boleto', icon: cartaoDebitoBoleto },
                { answerText: 'Todas as outras + Bitcoin e criptomoedas', icon: bitCoin }
            ]
        },
        {
            questionTitle: 'Como você pretende lidar com a logística e o frete de seus produtos?',
            answerOptions: [
                { answerText: 'Eu mesmo despacho nos Correios.', icon: euMesmoDespacho },
                { answerText: 'Quero terceirizar a entrega e já tenho uma empresa definida.', icon: queroTerceirizaJaDefini },
                { answerText: 'Quero terceirizar a entrega mas ainda não defini ninguém.', icon: queroTerceirizarMasNaoDefini },
                { answerText: 'Não sei ainda.', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Você utiliza algum sistema à parte para gerenciar seu estoque? ',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não, faço tudo de maneira manual', icon: nao7 },
                { answerText: 'Não, mas pretendo', icon: naoMasPretendo7 },
                { answerText: 'Não sei ainda', icon: naoSei }
            ]
        },
        {
            questionTitle: 'Você pretende integrar o estoque físico com o estoque online?',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não, vou administrá-los de maneira separada.', icon: nao7 },
                { answerText: 'Por enquanto não.', icon: maximo1Mes },
                { answerText: 'Não sei, gostaria de mais informações.', icon: naoSei }
            ]
        },
        {
            questionTitle: 'Você pretende utilizar ferramentas de automação em seu e-commerce(recuperação de carrinho, e-mails, chatbot etc)?',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não', icon: nao7 },
                { answerText: 'Gostaria de entender mais à respeito', icon: gostariaDeEntender },
                { answerText: 'Mais pra frente', icon: masParaFrente }
            ]
        },
        {
            questionTitle: '',
            answerOptions: []
        },
        {
            questionTitle: 'Para quando você precisa do e-commerce/loja virtual no ar?',
            answerOptions: [
                { answerText: 'Imediatamente', icon: imediatamente },
                { answerText: 'Em até 2 semanas', icon: emAte1Semana },
                { answerText: 'Em até 1 mês, no máximo', icon: proximasSemanas },
                { answerText: 'Para os próximos meses', icon: maximo1Mes }
            ]
        }
    ]

    return (
        <>
            <Header>
                <header>
                    {positonPath < 21 &&
                        <button type="button" onClick={handleBackPositionPath}>
                            <img src={arrowLeft} alt="" />
                            <span>voltar</span>
                        </button>
                    }

                    {positonPath < 20 &&
                        <h2>{positonPath + 1}/20</h2>
                    }
                </header>
            </Header>


            {positonPath !== 18 && positonPath <= 19 &&
                <>
                    <ContentHeader>
                        <h1 dangerouslySetInnerHTML={{ __html: questions[positonPath].questionTitle }} ></h1>
                        <h2>{questions[positonPath].questionSubtitle}</h2>
                    </ContentHeader>
                    <Container>
                        <Content>
                            <AnimateSharedLayout>
                                {questions[positonPath].answerOptions.map((answerOption) => (
                                    <motion.div variants={containerTransation} initial="hidden" animate="show" key={positonPath + answerOption.answerText} className="ItemOption" onClick={() => handleNextPositionPath(questions[positonPath].questionTitle, answerOption.answerText, positonPath)}>
                                        <div className="CircleBox">
                                            <img src={answerOption.icon} alt="" />
                                        </div>
                                        <p>{answerOption.answerText}</p>
                                    </motion.div>
                                ))}
                            </AnimateSharedLayout>
                        </Content>
                    </Container>
                </>
            }

            {positonPath === 18 &&
                <Container>
                    <div className='header'>
                        <h1>Você já possui algum e-commerce que gostaria de usar como referência?</h1>
                        <h3>Se não tiver apenas deixe em branco e clique em 'avançar'</h3>
                    </div>

                    <div className="inputBox">
                        <div className="SelectOption">
                            <p>URL 1</p>
                            <input list="reference1" name='reference1' value={references.reference1} onChange={handleChange} placeholder="Cole aqui a url do site de referência" />

                        </div>
                        <div className="SelectOption">
                            <p>URL 2</p>
                            <input list="reference2" name='reference2' value={references.reference2} onChange={handleChange} placeholder="Cole aqui a url do site de referência" />
                        </div>
                        <button className="manualButton" type="button" onClick={() => handleNextPositionPath("Você já possui algum e-commerce que gostaria de usar como referência?", references, positonPath)}> Avançar</button>
                    </div>
                </Container>
            }

            {positonPath === 20 &&
                <>
                    <ContentHeader>
                        <h1>Para finalizar, gostariamos de o conhecer melhor.</h1>
                    </ContentHeader>
                    <Container>
                        <ContainerForm onSubmit={handleSendBrifing}>
                            <div className="inputText">
                                <div>
                                    <input type="text" placeholder="Nome" value={name} onChange={event => setName(event.target.value)} />
                                    {errors.name && <p>{errors.name}</p>}
                                </div>
                                <div>
                                    <input type="email" placeholder="Email" value={email} onChange={event => setEmail(event.target.value)} />
                                    {errors.email && <p>{errors.email}</p>}
                                </div>
                                <input type="tel" placeholder="Telefone" value={phone} onChange={event => setPhone(event.target.value)} />
                                <input type="text" placeholder="Nome da Empresa" value={companyName} onChange={event => setCompanyName(event.target.value)} />
                                <input type="text" placeholder="Cupom" value={cupomName} onChange={event => setCupomName(event.target.value)} />
                            </div>
                            <CompanyTypeContainer>
                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('individual') }}
                                    isActive={type === 'individual'}
                                    activeColor="green"
                                >
                                    <img src={individual} alt="Individual" />
                                    <span>Sou um Particular</span>
                                </RadioBox>

                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('startup') }}
                                    isActive={type === 'startup'}
                                    activeColor="green"
                                >
                                    <img src={startup} alt="Startup" />
                                    <span>Startup</span>
                                </RadioBox>

                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('sme') }}
                                    isActive={type === 'sme'}
                                    activeColor="green"
                                >
                                    <img src={sme} alt="PME" />
                                    <span>PME</span>
                                </RadioBox>

                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('agency') }}
                                    isActive={type === 'agency'}
                                    activeColor="green"
                                >
                                    <img src={agency} alt="Individual" />
                                    <span>Agência</span>
                                </RadioBox>

                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('large_enterprise') }}
                                    isActive={type === 'large_enterprise'}
                                    activeColor="green"
                                >
                                    <img src={large_enterprise} alt="Individual" />
                                    <span>Grande Empresa</span>
                                </RadioBox>

                            </CompanyTypeContainer>

                            <button type="submit" disabled={isLoading}>{isLoading && <i className="fa fa-spinner fa-spin"></i>} Finalizar</button>

                        </ContainerForm>
                    </Container>
                </>
            }
            {positonPath === 21 &&
                <Container>
                    <motion.div
                        className="confirmIcon"
                        variants={containerTransation}
                        initial="hidden"
                        animate="show"
                    >
                        <img src={confirm} alt="Obrigado" />
                        <h1>Parabéns, você deu um importante passo.</h1>
                        <p>Um especialista da Brutalit entrará em contato com você o quanto antes!</p>
                        <button className="manualButton" type="button" onClick={() => { onSelectPath('main') }}>Comece um briefing de outros produtos</button>
                    </motion.div>


                </Container>
            }


            <Footer>
                <a href="https://www.brutalit.com.br"><p>Powered by Brutalit</p></a>
            </Footer>
        </>
    )
}