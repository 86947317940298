import styled from "styled-components"
import { transparentize } from 'polished'

export const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    height: calc(100vh - 13.6rem);
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media(min-width: 540px){
        .inputBox {
            margin: 5rem 0;
        }
    }

    @media(max-width: 867px){
        height: auto;
    }

    @media(max-height: 805px){
        height: auto;
        margin-top:1rem;
        margin-bottom:1rem;
    }

    .header{
        h1{
            mark{
                background: ${transparentize(0.4, '#C3F207')};
            }
        }
    }

    .manualButton{
            margin: 1rem 0;
            padding:0.4rem;
            font-size: 1.25rem;
            font-weight: bold;
            color: black;
            background: var(--green);
            border: 1px solid ${transparentize(0.4, '#C3F207')};
            border-radius: 1rem;

            transition: filter 0.2s;

            &:hover {
                filter: brightness(0.9)
            }
    }

    .SelectOption {
        display:flex;
        flex-direction: column;
        
        align-items: center;
        justify-content: center;

        margin: 2rem 0;

        input {
            width: 100%;
            max-width: 580px;
            padding: 0 1.5rem;
            margin: 1rem 0;
            height: 3em;
            border-radius: 0.25rem;

            border: 1px solid #d7d7d7;
            background: #e7e9ee;

            font-weight: 400;
            font-size: 1rem;

            &::placeholder {
                color: var(--text-body);
            }
        }


    }
    
    .confirmIcon{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80vh;

        p {
            font-size: 1.25rem;
            margin: 1rem 0;
        }

        img{
            margin: 1rem 0;
            width: 5rem;
        }
    }
`

export const ContentHeader = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    text-align:center;

    @media(max-width: 870px){
        height: auto;
    }

    @media(min-width: 870px){
        .header{
            position: absolute;
            top: 6.5rem;
            left: 0;
            right: 0;
        }
    }
    
    h1{
        mark{
            background: ${transparentize(0.4, '#C3F207')};
        }
    }

    @media(min-width: 540px){
        .header {
            height: 88px;
    }
        .inputBox {
            margin: 5rem 0;
        }
    }`

export const Header = styled.header`
    text-align: center;

    header {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 2rem 0 2rem 0;      
        
        button{
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            margin-left: 1rem;
            border-radius: 10px;

            font-size: 1.25rem;
            font-weight: bold;
            color: black;
            background: transparent;
            border: 1px solid ${transparentize(0.4, '#C3F207')};

            transition: background 0.2s;

            &:hover {
                background: ${transparentize(0.4, '#C3F207')}
            }

            img{
                padding: 0.5rem 0 0.2rem 1rem;
            }

            span{
                padding: 0 1.5rem 0.2rem 1.5rem;
            }


        }
    }
`

export const Footer = styled.footer`
    text-align: center;

    font-size: 1.25rem;
    font-weight: bold;
    text-decoration: underline;

    a {
        text-decoration: none;
        color: black;
    }
    
`

export const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;

    @media(max-width: 1074px){
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    .ItemOption {
        width: 13.8rem;
        margin: 2rem auto;
        text-align: center;

        @media(max-width: 1074px){
            margin: 2rem auto;
            width: 10.8rem;
        }

        p {
            margin-top: 0.25rem;
            font-size: 1.25rem;
            font-weight: bold;
        }
    }

    .CircleBox {
        width: 100%;
        height: 13.8rem;
        border-radius: 100%;
        border: 1px solid ${transparentize(0.4, 'black')};

        display:flex;
        align-items:center;
        justify-content: center;
        

        transition: box-shadow 0.2s;

        &:hover{
            box-shadow: 0px 0px 15px -2px rgba(0,0,0,0.8);
            background: ${transparentize(0.4, '#C3F207')};
        }

        overflow: hidden;
        cursor: pointer;

        img{
            max-width: 80%;
        }

        @media(max-width: 1074px){
            height: 10.8rem;
        }
    }
`

export const ContainerForm = styled.form`
    max-width: 100%;
    width: 80%;
    max-height: 100%;
    margin: 0 auto;

    @media(max-width: 870px){
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .inputText{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        gap: 2rem;

        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
    }

    input {
        width: 100%;
        padding: 0 1.5rem;
        height: 4rem;
        border-radius: 0.25rem;

        border: 1px solid #d7d7d7;
        background: #e7e9ee;

        font-weight: 400;
        font-size: 1rem;

        &::placeholder {
            color: var(--text-body);
        }
    }

    button[type="submit"] {
        width: 100%;
        max-width: 374px;
        padding: 0 1.5rem;
        height: 4rem;
        background: ${transparentize(0.64, '#4A90E2')};
        color: #000;
        border-radius: 1rem;
        border: 0;
        font-size: 1rem;
        margin-top: 1.5rem;
        font-weight: 600;

        transition: filter 0.2s;
        &:hover {
            filter: brightness(0.9);
        }
    }

    p {
        margin-top: 0.5rem;
        color: #f00e0e;
    }
`

export const CompanyTypeContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr)); 
    gap: 0.5rem;

    margin: 1rem 0;

    span {
        display: inline-block;
        margin-top: 1rem;
        font-size: 1rem;
        color: var(--text-title);
    }
`

interface RadioBoxProps {
    isActive: boolean;
    activeColor: 'green' | 'red';
}

const colors = {
    green: '#808080',
    red: '#e52e4d'
};

export const RadioBox = styled.button<RadioBoxProps>`
    border: 1px solid #d7d7d7;
    height: 10rem;
    width: 9rem;
    margin: 0 auto;
    border-radius: 1.5rem;
    outline:none;

    background: ${(props) => props.isActive
        ? transparentize(0.9, colors[props.activeColor])
        : transparentize(0.64, '#4A90E2')};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: border-color 0.2s;

    &:hover {
        border-color: #aaa;
    }
 
`