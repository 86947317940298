import { useState } from "react";
import { BriefingMain } from "./components/BriefingMain";
import { GoogleAds } from "./components/GoogleAds";
import { GestaoDeRedes } from "./components/GestaoDeRedes";
import { GlobalStyle } from "./styles/global";
import { AnunciosNasRedesSociais } from "./components/AnunciosNasRedesSociais";
import { CriacaoDeEcommerce } from "./components/CriacaoDeEcommerce";
import { CriacaoDeWebsite } from "./components/CriacaoDeWebsite";
import { DesenvolvimentoApp } from "./components/DesenvolvimentoApp";
// import { Route, Link } from "react-router-dom";

export function App() {
  const [state, setState] = useState('main')

  function handleTriggerPath(selectPath: string) {
    setState(selectPath)
    window.scrollTo(0, 0)
  }

  return (
    <div className="App">
      {/* <Route exact path="/" component={BriefingMain} /> */}
      {/* <Route exact path="/GoogleAds" component={GoogleAds} /> */}
      {state === 'main' && <BriefingMain onSelectPath={handleTriggerPath} />}
      {state === 'CriacaoDeWebsite' && <CriacaoDeWebsite onSelectPath={handleTriggerPath} />}
      {state === 'CriacaoDeEcommerce' && <CriacaoDeEcommerce onSelectPath={handleTriggerPath} />}
      {state === 'GestaoDeRedes' && <GestaoDeRedes onSelectPath={handleTriggerPath} />}
      {state === 'AnunciosNasRedesSociais' && <AnunciosNasRedesSociais onSelectPath={handleTriggerPath} />}
      {state === 'GoogleAds' && <GoogleAds onSelectPath={handleTriggerPath} />}
      {state === 'DesenvolvimentoApp' && <DesenvolvimentoApp onSelectPath={handleTriggerPath} />}
      <GlobalStyle />
    </div>
  );
}
