import axios from 'axios';

export const api = axios.create({
    baseURL: 'https://lydc4x388i.execute-api.sa-east-1.amazonaws.com/dev',
})

export const ip = axios.create({
    baseURL: 'https://extreme-ip-lookup.com/json/'
})

export const teste = axios.create({
    baseURL: 'http://localhost:3000/dev'
})