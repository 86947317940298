import fortalecerAutoridade from '../../assets/GoogleAds/Options_1/fortalecerAutoridade.svg'
import aumentarSeguidores from '../../assets/GestaoDeRedes/Options_16/numeroDeSeguidores.svg'

import sim from '../../assets/GoogleAds/Options_3/sim.svg'
import nao from '../../assets/GoogleAds/Options_3/nao.svg'
import naoTenhoLojaFisica from '../../assets/GoogleAds/Options_3/naoTenhoLojaFisica.svg'
import naoSeiAinda from '../../assets/GoogleAds/Options_3/naoSeiAinda.svg'

import website from '../../assets/GoogleAds/Options_4/website.svg'
import eCommerce from '../../assets/GoogleAds/Options_4/eCommerce.svg'
import landingPage from '../../assets/GoogleAds/Options_4/landingPage.svg'
import naoMaisGostaria from '../../assets/GoogleAds/Options_4/naoMaisGostaria.svg'

import minhaCustodia from '../../assets/GoogleAds/Options_5/minhaCustodia.svg'
import custodiaTerceiros from '../../assets/GoogleAds/Options_5/custodiaTerceiros.svg'
import custodiaTerceirosagencia from '../../assets/GoogleAds/Options_5/custodiaTerceirosagencia.svg'
import naoTenhoPagina from '../../assets/GoogleAds/Options_5/naoTenhoPagina.svg'

import sim6 from '../../assets/GoogleAds/Options_6/sim.svg'
import nao6 from '../../assets/GoogleAds/Options_6/nao.svg'
import simMasEuMesmo from '../../assets/GestaoDeRedes/Options_13/simMasEuMesmo.svg'
import naoSeiGostaria from '../../assets/GoogleAds/Options_6/naoSeiGostaria.svg'

import sim7 from '../../assets/GoogleAds/Options_7/sim.svg'
import nao7 from '../../assets/GoogleAds/Options_7/nao.svg'
import naoMasPretendo7 from '../../assets/GoogleAds/Options_7/naoMasPretendo.svg'
import naoSei from '../../assets/GoogleAds/Options_7/naoSei.svg'

import visitasNoLocal from '../../assets/GoogleAds/Options_8/visitasNoLocal.svg'
import acoesDeRua from '../../assets/GoogleAds/Options_8/acoesDeRua.svg'
import redesSociais from '../../assets/GoogleAds/Options_8/redesSociais.svg'
import pesquisaGoogle from '../../assets/GoogleAds/Options_8/pesquisaGoogle.svg'

import simTerceiros from '../../assets/GoogleAds/Options_9/simTerceiros.svg'
import simNaoTiveResultados9 from '../../assets/GoogleAds/Options_9/simNaoTiveResultados.svg'
import naoPrimeiraVez from '../../assets/GoogleAds/Options_9/naoPrimeiraVez.svg'

import proximoMinhaLoja from '../../assets/GoogleAds/Options_12/proximoMinhaLoja.svg'
import cidadeInteria from '../../assets/GoogleAds/Options_12/cidadeInteria.svg'
import estadoInteiro from '../../assets/GoogleAds/Options_12/estadoInteiro.svg'
import brasilTodo from '../../assets/GoogleAds/Options_12/brasilTodo.svg'

import somentoHorarioComercial from '../../assets/GoogleAds/Options_13/somentoHorarioComercial.svg'
import tempoTodo from '../../assets/GoogleAds/Options_13/tempoTodo.svg'
import emPromocoes from '../../assets/GoogleAds/Options_13/emPromocoes.svg'
import naoSeiGostariaDeInformacoes13 from '../../assets/GoogleAds/Options_13/naoSeiGostariaDeInformacoes.svg'

import apenasUm from '../../assets/GoogleAds/Options_14/apenasUm.svg'
import de2a5 from '../../assets/GoogleAds/Options_14/de2a5.svg'
import de5a10 from '../../assets/GoogleAds/Options_14/de5a10.svg'
import maisDe10 from '../../assets/GoogleAds/Options_14/maisDe10.svg'

import redirecionarSite from '../../assets/GoogleAds/Options_15/atravesDeFormulario.svg'
import atravesLigacoes from '../../assets/GoogleAds/Options_15/atravesLigacoes.svg'
import receberMensagem from '../../assets/GoogleAds/Options_15/atravesDeDM.svg'
import naoSeiGostariaDeInformacoes15 from '../../assets/GoogleAds/Options_15/naoSeiGostariaDeInformacoes.svg'

import euMesmo from '../../assets/GoogleAds/Options_16/euMesmo.svg'
import alguemDaMinhaEquipe from '../../assets/GoogleAds/Options_16/alguemDaMinhaEquipe.svg'
import precisoDeUmaConsultoria from '../../assets/GoogleAds/Options_16/precisoDeUmaConsultoria.svg'
import queroColocarChatbots from '../../assets/GoogleAds/Options_16/queroColocarChatbots.svg'

import sim17 from '../../assets/GestaoDeRedes/Options_17/simImpulsionei.svg'
import apenasVender from '../../assets/AnunciosNasRedesSociais/Option_10/apenasVender.svg'
import impulsionarPosts from '../../assets/AnunciosNasRedesSociais/Option_10/impulsionarPosts.svg'
import queroImpulsionar from '../../assets/AnunciosNasRedesSociais/Option_10/queroImpulsionar.svg'
import queroPromoverMarca from '../../assets/AnunciosNasRedesSociais/Option_10/queroPromoverMarca.svg'

import maquinaSim from '../../assets/AnunciosNasRedesSociais/Option_14/maquinaSim.svg'
import maquinaNao from '../../assets/AnunciosNasRedesSociais/Option_14/maquinaNao.svg'

import vouUtilizarMinhasFotos from '../../assets/AnunciosNasRedesSociais/Option_15/vouUtilizarMinhasFotos.svg'
import queroCriarArtes from '../../assets/AnunciosNasRedesSociais/Option_15/queroCriarArtes.svg'
import queroFazerAmbos from '../../assets/AnunciosNasRedesSociais/Option_15/queroFazerAmbos.svg'

import relatorioBasico from '../../assets/GoogleAds/Options_18/relatorioBasico.svg'
import relatorioPersonalidado from '../../assets/GoogleAds/Options_18/relatorioPersonalidado.svg'
import naoPrecisoDeRelatorio from '../../assets/GoogleAds/Options_18/naoPrecisoDeRelatorio.svg'
import naoSeiGostariaDeInformacoes from '../../assets/GoogleAds/Options_18/naoSeiGostariaDeInformacoes.svg'

import MilPorMes from '../../assets/GoogleAds/Options_19/MilPorMes.svg'
import MilAteTresMilPorMes from '../../assets/GoogleAds/Options_19/MilAteTresMilPorMes.svg'
import TresMilAteSeisMilPorMes from '../../assets/GoogleAds/Options_19/TresMilAteSeisMilPorMes.svg'
import AcimaDeDezMil from '../../assets/GoogleAds/Options_19/AcimaDeDezMil.svg'

import imediatamente from '../../assets/GoogleAds/Options_20/imediatamente.svg'
import emAte1Semana from '../../assets/GoogleAds/Options_20/emAte1Semana.svg'
import proximasSemanas from '../../assets/GoogleAds/Options_20/proximasSemanas.svg'
import maximo1Mes from '../../assets/GoogleAds/Options_20/1mes.svg'

import individual from '../../assets/form/individual.svg'
import startup from '../../assets/form/startup.svg'
import sme from '../../assets/form/sme.svg'
import agency from '../../assets/form/agency.svg'
import large_enterprise from '../../assets/form/large_enterprise.svg'
import confirm from '../../assets/form/confirm.svg'

import arrowLeft from '../../assets/arrow-left.svg'

import { Container, Content, Footer, ContainerForm, Header, CompanyTypeContainer, RadioBox, ContentHeader } from "../../styles/questionsStyles"
import { FormEvent, useEffect, useState } from 'react'
import { api, ip } from '../../services/api';

import { AnimateSharedLayout, motion } from 'framer-motion';

interface PathSelectionProps {
    onSelectPath: (selectPath: string) => void;
}

interface ErrorProps {
    name: string,
    email: string
}

export function AnunciosNasRedesSociais({ onSelectPath }: PathSelectionProps) {

    const [type, setType] = useState('')
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [cupomName, setCupomName] = useState('');

    const [errors, setErrors] = useState<ErrorProps>({
        name: '',
        email: ''
    })

    const [positonPath, setPositionPath] = useState(0)
    const [choice, setChoices] = useState<Object[]>([])
    const [segment, setSegment] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const containerTransation = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    }

    async function handleExit() {
        const userIP = await ip.get("").then(response => {
            return {
                "Region": response.data.region,
                "IP": response.data.query
            }
        }).catch(() => {
            console.log('Request failed');
        })

        const choices = {
            choices: choice,
            userData: userIP
        }
        const response = await api.post('/abandonedBriefing', choices)
        console.log(response)
    };

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    })

    const alertUser = (event: any) => {
        event.preventDefault();
        event.returnValue = '';
        handleExit();
    }

    function handleNextPositionPath(question: string, choiceSelected: string, index: number) {
        if (positonPath > 20) return

        question = question.replace("<mark>", "").replace("</mark>", "")

        const choiceResponce = { "question": question, "answer": choiceSelected }

        const arrayChoice = [...choice]
        arrayChoice[index] = choiceResponce
        setChoices(arrayChoice)

        setPositionPath(positonPath + 1)
        window.scrollTo(0, 0)
    }

    async function handleSendBrifing(event: FormEvent) {
        event.preventDefault();
        setIsLoading(true)

        const isFormValid = validateForm();

        if (isFormValid) {

            const userIP = await ip.get("").then(response => {
                return {
                    "Região": response.data.region,
                    "IP": response.data.query
                }
            }).catch(() => {
                console.log('Request failed');
            })

            const newBriefing = {
                name: name,
                email: email,
                phone: phone,
                companyName: companyName,
                type: type,
                choices: choice,
                briefingType: "Anúncios nas Redes Sociais",
                cupomName: cupomName,
                UserDataIP: userIP
            }



            const response = await api.post('/briefing', newBriefing)
            setIsLoading(false)

            if (response) {
                setPositionPath(positonPath + 1)
            }

        } else {
            window.scrollTo(0, 0)
            setIsLoading(false)
        }
    }

    function validateForm() {
        let errors = {
            name: '',
            email: ''
        };
        let isValid = true;

        if (!name.trim()) {
            errors.name = "Campo nome obrigatorio";
            isValid = false;
        }

        if (!email) {
            errors.email = "Email obrigatorio"
            isValid = false;
        } else if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            errors.email = "Email invalido"
            isValid = false;
        }

        setErrors(errors)
        return isValid;
    }

    function handleBackPositionPath() {
        if (positonPath <= 0) onSelectPath('main')

        setPositionPath(positonPath - 1)
    }

    const questions = [
        {
            questionTitle: 'Qual o seu <mark>principal objetivo</mark> ao anunciar no Facebook e Instagram?',
            answerOptions: [
                { answerText: 'Redirecionar para o site', icon: redirecionarSite },
                { answerText: 'Fortalecer a autoridade da marca', icon: fortalecerAutoridade },
                { answerText: 'Receber mensagens', icon: receberMensagem },
                { answerText: 'Aumentar seguidores', icon: aumentarSeguidores }
            ]
        },
        {
            questionTitle: '',
            answerOptions: []
        },
        {
            questionTitle: 'Você deseja <mark>direcionar visitas para sua loja física?</mark>',
            answerOptions: [
                { answerText: 'Sim', icon: sim },
                { answerText: 'Não', icon: nao },
                { answerText: 'Não, não tenho loja física', icon: naoTenhoLojaFisica },
                { answerText: 'Não sei ainda', icon: naoSeiAinda }
            ]
        },
        {
            questionTitle: 'Você já possui uma página web <mark>(website, e-commerce ou landing-page)</mark>?',
            answerOptions: [
                { answerText: 'Website', icon: website },
                { answerText: 'E-commerce', icon: eCommerce },
                { answerText: 'Landing-page', icon: landingPage },
                { answerText: 'Não, mas gostaria de criar', icon: naoMaisGostaria }
            ]
        },
        {
            questionTitle: 'Caso possua, a página web (website, e-commerce, landing page etc) <mark>está sob sua custódia ou de terceiros?',
            answerOptions: [
                { answerText: 'Está sob minha custódia, tenho controle total', icon: minhaCustodia },
                { answerText: 'Custódia de terceiros, alugo uma plataforma', icon: custodiaTerceiros },
                { answerText: 'ustódia de terceiros, uma agência cuida para mim', icon: custodiaTerceirosagencia },
                { answerText: 'Não tenho uma página web', icon: naoTenhoPagina }
            ]
        },
        {
            questionTitle: 'Você divulga o seu negócio em algum <mark>Marketplace?</mark>',
            questionSubtitle: '(Mercado Livre, Amazon, Americanas, OLX etc)',
            answerOptions: [
                { answerText: 'Sim', icon: sim7 },
                { answerText: 'Não', icon: nao7 },
                { answerText: 'Não, mas pretendo começar', icon: naoMasPretendo7 },
                { answerText: 'Não, mas gostaria de saber mais à respeito', icon: naoSei }
            ]
        },
        {
            questionTitle: '<mark>Você já fez</mark> algum anúncio pago no Facebook ou Instagram?',
            answerOptions: [
                { answerText: 'Sim, impulsionei posts', icon: sim17 },
                { answerText: 'Sim, terceiros eram responsáveis por isso', icon: simTerceiros },
                { answerText: 'Sim, mas não tive resultados', icon: simNaoTiveResultados9 },
                { answerText: 'Não, vai ser a primeira vez', icon: naoPrimeiraVez }
            ]
        },
        {
            questionTitle: 'Você já possui conta no <mark>Gerenciador de Anúncios?</mark>',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não', icon: nao6 },
                { answerText: 'Sim, mas não sei se configurei corretamente', icon: simMasEuMesmo },
                { answerText: 'Não sei o que é', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Como sua empresa <mark>consegue clientes</mark> hoje?',
            answerOptions: [
                { answerText: 'Visitas no local / Indicações', icon: visitasNoLocal },
                { answerText: 'Ações de Rua', icon: acoesDeRua },
                { answerText: 'Redes Sociais', icon: redesSociais },
                { answerText: 'Pesquisa no Google', icon: pesquisaGoogle }
            ]
        },
        {
            questionTitle: 'Você pretende utilizar os anúncios apenas para vender ou para <mark>outras finalidades</mark>?',
            answerOptions: [
                { answerText: 'Apenas para vender', icon: apenasVender },
                { answerText: 'Quero também impulsionar posts do feed', icon: queroImpulsionar },
                { answerText: 'Quero também promover a marca localmente', icon: queroPromoverMarca },
                { answerText: 'Impulsionar posts e promover a marca localmente', icon: impulsionarPosts }
            ]
        },
        {
            questionTitle: 'Em quais <mark>áreas geográficas</mark> você gostaria que seus anúncios fossem exibidos?',
            answerOptions: [
                { answerText: 'Próximo a minha loja e aos bairros vizinhos', icon: proximoMinhaLoja },
                { answerText: 'Na cidade inteira', icon: cidadeInteria },
                { answerText: 'No estado inteiro', icon: estadoInteiro },
                { answerText: 'No Brasil todo', icon: brasilTodo }
            ]
        },
        {
            questionTitle: 'Você quer <mark>manter os anúncios ativos</mark> com qual frequência?',
            answerOptions: [
                { answerText: 'Somente durante o horário comercial', icon: somentoHorarioComercial },
                { answerText: 'O tempo todo, pode ficar veiculado 24hs', icon: tempoTodo },
                { answerText: 'Em Promoções / Datas Comemorativas', icon: emPromocoes },
                { answerText: 'Não sei', icon: naoSeiGostariaDeInformacoes13 }
            ]
        },
        {
            questionTitle: 'Quantos produtos / serviços você deseja anunciar <mark>simultaneamente</mark> ?',
            answerOptions: [
                { answerText: 'Apenas um', icon: apenasUm },
                { answerText: 'De 2 a 5', icon: de2a5 },
                { answerText: 'De 5 a 10', icon: de5a10 },
                { answerText: 'Mais de 10', icon: maisDe10 }
            ]
        },
        {
            questionTitle: 'Você gostaria de contratar um <mark>Copywriter profissional</mark> para escrever seus anúncios?',
            answerOptions: [
                { answerText: 'Sim', icon: maquinaSim },
                { answerText: 'Não', icon: maquinaNao },
                { answerText: 'Sim, mas gostaria de mais informações.', icon: simMasEuMesmo },
                { answerText: 'Não sei do que se trata.', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Você gostaria de utilizar nos anúncios as suas fotos e vídeos próprios ou <mark>ter criativos específicos</mark> para isso?',
            answerOptions: [
                { answerText: 'Vou utilizar minhas fotos e vídeos', icon: vouUtilizarMinhasFotos },
                { answerText: 'Quero criar artes específicas para cada anúncio', icon: queroCriarArtes },
                { answerText: 'Quero fazer ambos', icon: queroFazerAmbos },
                { answerText: 'Não sei, gostaria de entender mais', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: '<mark>Como você gostaria de ser contatado</mark> pelos clientes que visualizarem os anúncios?',
            answerOptions: [
                { answerText: 'Através de um formulário de cadastro', icon: redirecionarSite },
                { answerText: 'Através de ligações telefônicas', icon: atravesLigacoes },
                { answerText: 'Através de DM, Messenger ou WhatsApp.', icon: receberMensagem },
                { answerText: 'Não sei, gostaria de orientações.', icon: naoSeiGostariaDeInformacoes15 }
            ]
        },
        {
            questionTitle: 'Quem será o <mark>responsável pelo atendimento</mark> aos clientes que vierem através dos anúncios?',
            answerOptions: [
                { answerText: 'Eu mesmo', icon: euMesmo },
                { answerText: 'Alguém da minha equipe', icon: alguemDaMinhaEquipe },
                { answerText: 'Preciso de uma consultoria de vendas', icon: precisoDeUmaConsultoria },
                { answerText: 'Quero colocar chatbots', icon: queroColocarChatbots }
            ]
        },
        {
            questionTitle: 'Qual tipo de <mark>relatório</mark> sobre suas campanhas você gostaria de receber?',
            answerOptions: [
                { answerText: 'Relatório básico', icon: relatorioBasico },
                { answerText: 'Relatório personalizado', icon: relatorioPersonalidado },
                { answerText: 'Não preciso de relatórios por enquanto', icon: naoPrecisoDeRelatorio },
                { answerText: 'Não sei, gostaria de mais informações', icon: naoSeiGostariaDeInformacoes }
            ]
        },
        {
            questionTitle: 'Qual sua <mark>verba de anúncio</mark> mensal pretendida?',
            answerOptions: [
                { answerText: 'R$400 a R$1000/mês', icon: MilPorMes },
                { answerText: 'R$1.001 a R$3.000/mês', icon: MilAteTresMilPorMes },
                { answerText: 'R$ 3.001 a R$ 6.000/mês', icon: TresMilAteSeisMilPorMes },
                { answerText: 'Acima de R$10.000/mês', icon: AcimaDeDezMil }
            ]
        },
        {
            questionTitle: 'Para quando você precisa colocar seus <mark>anúncios no ar</mark> ?',
            answerOptions: [
                { answerText: 'Imediatamente', icon: imediatamente },
                { answerText: 'Em até 1 semana', icon: emAte1Semana },
                { answerText: 'Para as próximas semanas', icon: proximasSemanas },
                { answerText: 'Em 1 mês, no máximo', icon: maximo1Mes }
            ]
        }
    ]

    return (
        <>
            <Header>
                <header>
                    {positonPath < 21 &&
                        <button type="button" onClick={handleBackPositionPath}>
                            <img src={arrowLeft} alt="" />
                            <span>voltar</span>
                        </button>
                    }

                    {positonPath < 20 &&
                        <h2>{positonPath + 1}/20</h2>
                    }
                </header>
            </Header>


            {positonPath !== 1 && positonPath <= 19 &&
                <>
                    <ContentHeader>
                        <h1 dangerouslySetInnerHTML={{ __html: questions[positonPath].questionTitle }} ></h1>
                        <h2>{questions[positonPath].questionSubtitle}</h2>
                    </ContentHeader>
                    <Container>
                        <Content>
                            <AnimateSharedLayout>
                                {questions[positonPath].answerOptions.map((answerOption) => (
                                    <motion.div variants={containerTransation} initial="hidden" animate="show" key={positonPath + answerOption.answerText} className="ItemOption" onClick={() => handleNextPositionPath(questions[positonPath].questionTitle, answerOption.answerText, positonPath)}>
                                        <div className="CircleBox">
                                            <img src={answerOption.icon} alt="" />
                                        </div>
                                        <p>{answerOption.answerText}</p>
                                    </motion.div>
                                ))}
                            </AnimateSharedLayout>
                        </Content>
                    </Container>
                </>
            }
            {positonPath === 1 &&
                <Container>
                    <div className='header'>
                        <h1>Qual o seu <mark>nicho de mercado</mark>?</h1>
                    </div>

                    <div className="SelectOption">
                        <input list="segmento" name='segmento' value={segment} onChange={event => setSegment(event.target.value)} placeholder="Nos conte o segmento de sua loja" />
                        <datalist id="segmento">
                            <option value="Academia" />
                            <option value="Acessórios automotivos" />
                            <option value="Advocacia" />
                            <option value="Agência" />
                            <option value="Alimentos e Bebidas" />
                            <option value="Arquitetura" />
                            <option value="Artes e Antiguidades" />
                            <option value="Artesanato" />
                            <option value="Artigos promocionais" />
                            <option value="Artigos religiosos" />
                            <option value="Bares e Restaurantes" />
                            <option value="Bêbes e Cia" />
                            <option value="Bebidas Alcoólicas" />
                            <option value="Blockchain" />
                            <option value="Brinquedos e Colecionáveis" />
                            <option value="Casa Noturna" />
                            <option value="Casa de Festas" />
                            <option value="Casa e Decoração" />
                            <option value="Cervejaria" />
                            <option value="Clínica" />
                            <option value="Colégio" />
                            <option value="Construção Civil" />
                            <option value="Contabilidade" />
                            <option value="Cosméticos, Perfumaria e Cuidados Pessoais" />
                            <option value="Creche" />
                            <option value="Criptomoedas" />
                            <option value="Curso presencial" />
                            <option value="Curso online" />
                            <option value="Delivery" />
                            <option value="Dentista" />
                            <option value="Desenvolvimento de Softwares" />
                            <option value="Eletrodomésticos" />
                            <option value="Eletrônicos" />
                            <option value="Energia solar" />
                            <option value="Equipamentos para laboratório" />
                            <option value="Esporte e Lazer" />
                            <option value="Esteticista" />
                            <option value="Faculdade" />
                            <option value="Farmárcia" />
                            <option value="Festas e Eventos" />
                            <option value="Fitness e Suplementos" />
                            <option value="Fotografia" />
                            <option value="Games" />
                            <option value="Gráfica" />
                            <option value="Hotelaria" />
                            <option value="Informática" />
                            <option value="Ingressos" />
                            <option value="Instrumentos musicais" />
                            <option value="Investimentos" />
                            <option value="Inteligência Artificial" />
                            <option value="Livros e Revistas" />
                            <option value="Loja de informática" />
                            <option value="Loja de baterias" />
                            <option value="Loja de departamentos" />
                            <option value="Loja de carros" />
                            <option value="Médico" />
                            <option value="Mercado financeiro" />
                            <option value="Moda e Acessórios" />
                            <option value="Móveis" />
                            <option value="Música" />
                            <option value="Oficina automotiva" />
                            <option value="Órgão público" />
                            <option value="Papelaria e Escritório" />
                            <option value="Petshop" />
                            <option value="Política" />
                            <option value="Presentes, flores e cestas" />
                            <option value="Prestação de Serviço" />
                            <option value="Publicidade e Marketing" />
                            <option value="Relojoaria e Joalheria" />
                            <option value="Salão de Beleza" />
                            <option value="Saúde" />
                            <option value="Sex Shop" />
                            <option value="Supermercado" />
                            <option value="Telefonia e Celulares" />
                            <option value="Veterinária" />
                            <option value="Viagens e Turismo" />
                        </datalist>
                        <button className="manualButton" type="button" onClick={() => handleNextPositionPath("Qual o seu nicho de mercado?", segment, 1)}> Avançar</button>
                    </div>
                </Container>
            }

            {positonPath === 20 &&
                <>
                    <ContentHeader>
                        <h1>Para finalizar, gostariamos de o conhecer melhor.</h1>
                    </ContentHeader>
                    <Container>
                        <ContainerForm onSubmit={handleSendBrifing}>
                            <div className="inputText">
                                <div>
                                    <input type="text" placeholder="Nome" value={name} onChange={event => setName(event.target.value)} />
                                    {errors.name && <p>{errors.name}</p>}
                                </div>
                                <div>
                                    <input type="email" placeholder="Email" value={email} onChange={event => setEmail(event.target.value)} />
                                    {errors.email && <p>{errors.email}</p>}
                                </div>
                                <input type="tel" placeholder="Telefone" value={phone} onChange={event => setPhone(event.target.value)} />
                                <input type="text" placeholder="Nome da Empresa" value={companyName} onChange={event => setCompanyName(event.target.value)} />
                                <input type="text" placeholder="Cupom" value={cupomName} onChange={event => setCupomName(event.target.value)} />
                            </div>
                            <CompanyTypeContainer>
                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('individual') }}
                                    isActive={type === 'individual'}
                                    activeColor="green"
                                >
                                    <img src={individual} alt="Individual" />
                                    <span>Sou um Particular</span>
                                </RadioBox>

                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('startup') }}
                                    isActive={type === 'startup'}
                                    activeColor="green"
                                >
                                    <img src={startup} alt="Startup" />
                                    <span>Startup</span>
                                </RadioBox>

                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('sme') }}
                                    isActive={type === 'sme'}
                                    activeColor="green"
                                >
                                    <img src={sme} alt="PME" />
                                    <span>PME</span>
                                </RadioBox>

                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('agency') }}
                                    isActive={type === 'agency'}
                                    activeColor="green"
                                >
                                    <img src={agency} alt="Individual" />
                                    <span>Agência</span>
                                </RadioBox>

                                <RadioBox
                                    type="button"
                                    onClick={() => { setType('large_enterprise') }}
                                    isActive={type === 'large_enterprise'}
                                    activeColor="green"
                                >
                                    <img src={large_enterprise} alt="Individual" />
                                    <span>Grande Empresa</span>
                                </RadioBox>

                            </CompanyTypeContainer>

                            <button type="submit" disabled={isLoading}>{isLoading && <i className="fa fa-spinner fa-spin"></i>} Finalizar</button>

                        </ContainerForm>
                    </Container>
                </>
            }
            {positonPath === 21 &&
                <Container>
                    <motion.div
                        className="confirmIcon"
                        variants={containerTransation}
                        initial="hidden"
                        animate="show"
                    >
                        <img src={confirm} alt="Obrigado" />
                        <h1>Obrigado pelo seu Tempo</h1>
                        <p>Um e-mail foi enviado pra nossa equipe, e entraremos em contanto o quanto antes!</p>
                        <button className="manualButton" type="button" onClick={() => { onSelectPath('main') }}>Comece um briefing de outros produtos</button>
                    </motion.div>


                </Container>
            }


            <Footer>
                <a href="https://www.brutalit.com.br"><p>Powered by Brutalit</p></a>
            </Footer>
        </>
    )
}