import { Container } from "../styles/questionsStyles"

interface SelectInputProps {
  title: string;
  segment: string;
  setSegment: (value: string) => void;
  positonPath: number;
  handleNextPositionPath: (question: string, choiceSelected: string | object, index: number) => void;
}

export default function SelectInput({ title, segment, setSegment, positonPath, handleNextPositionPath }: SelectInputProps) {

  return (
    <Container>
      <div className='header'>
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
      </div>

      <div className="SelectOption">
        <input list="segmento" name='segmento' value={segment} onChange={event => setSegment(event.target.value)} placeholder="Nos conte o segmento de sua loja" />
        <datalist id="segmento">
          <option value="Academia" />
          <option value="Acessórios automotivos" />
          <option value="Advocacia" />
          <option value="Agência" />
          <option value="Alimentos e Bebidas" />
          <option value="Arquitetura" />
          <option value="Artes e Antiguidades" />
          <option value="Artesanato" />
          <option value="Artigos promocionais" />
          <option value="Artigos religiosos" />
          <option value="Bares e Restaurantes" />
          <option value="Bêbes e Cia" />
          <option value="Bebidas Alcoólicas" />
          <option value="Blockchain" />
          <option value="Brinquedos e Colecionáveis" />
          <option value="Casa Noturna" />
          <option value="Casa de Festas" />
          <option value="Casa e Decoração" />
          <option value="Cervejaria" />
          <option value="Clínica" />
          <option value="Colégio" />
          <option value="Construção Civil" />
          <option value="Contabilidade" />
          <option value="Cosméticos, Perfumaria e Cuidados Pessoais" />
          <option value="Creche" />
          <option value="Criptomoedas" />
          <option value="Curso presencial" />
          <option value="Curso online" />
          <option value="Delivery" />
          <option value="Dentista" />
          <option value="Desenvolvimento de Softwares" />
          <option value="Eletrodomésticos" />
          <option value="Eletrônicos" />
          <option value="Energia solar" />
          <option value="Equipamentos para laboratório" />
          <option value="Esporte e Lazer" />
          <option value="Esteticista" />
          <option value="Faculdade" />
          <option value="Farmárcia" />
          <option value="Festas e Eventos" />
          <option value="Fitness e Suplementos" />
          <option value="Fotografia" />
          <option value="Games" />
          <option value="Gráfica" />
          <option value="Hotelaria" />
          <option value="Informática" />
          <option value="Ingressos" />
          <option value="Instrumentos musicais" />
          <option value="Investimentos" />
          <option value="Inteligência Artificial" />
          <option value="Livros e Revistas" />
          <option value="Loja de informática" />
          <option value="Loja de baterias" />
          <option value="Loja de departamentos" />
          <option value="Loja de carros" />
          <option value="Médico" />
          <option value="Mercado financeiro" />
          <option value="Moda e Acessórios" />
          <option value="Móveis" />
          <option value="Música" />
          <option value="Oficina automotiva" />
          <option value="Órgão público" />
          <option value="Papelaria e Escritório" />
          <option value="Petshop" />
          <option value="Política" />
          <option value="Presentes, flores e cestas" />
          <option value="Prestação de Serviço" />
          <option value="Publicidade e Marketing" />
          <option value="Relojoaria e Joalheria" />
          <option value="Salão de Beleza" />
          <option value="Saúde" />
          <option value="Sex Shop" />
          <option value="Supermercado" />
          <option value="Telefonia e Celulares" />
          <option value="Veterinária" />
          <option value="Viagens e Turismo" />
        </datalist>
        <button className="manualButton" type="button" onClick={() => handleNextPositionPath("Qual o tipo de app você gostaria de criar", segment, positonPath)}> Avançar</button>
      </div>
    </Container>
  )
}