import otimaQualidade from '../../assets/CriacoECommerce/Option_1/otimaQualidade.svg'
import boaRelacao from '../../assets/CriacoECommerce/Option_1/boaRelacao.svg'
import qualidadeNaoePrioridade from '../../assets/CriacoECommerce/Option_1/qualidadeNaoePrioridade.svg'
import Frequentemente from '../../assets/GestaoDeRedes/Options_3/nao.svg'

import sim2 from '../../assets/CriacoECommerce/Option_2/sim.svg'
import naoJaTenhoExperiencia from '../../assets/CriacoECommerce/Option_2/naoJaTenhoExperiencia.svg'
import naoMasNaoTenhoExperiencia from '../../assets/CriacoECommerce/Option_2/naoMasNaoTenhoExperiencia.svg'
import contrateiOutrasPessoas from '../../assets/CriacoECommerce/Option_2/contrateiOutrasPessoas.svg'

import queroApenasDeixarNoAr from '../../assets/CriacaoDeWebsite/Option_16/queroApenasDeixarNoAr.svg'
import queroPublicar from '../../assets/CriacoECommerce/Option_13/queroPublicar.svg'


import sim4 from '../../assets/CriacoECommerce/Option_4/sim.svg'
import nao4 from '../../assets/CriacoECommerce/Option_4/nao.svg'
import simMasPerdio from '../../assets/CriacoECommerce/Option_4/simMasPerdio.svg'
import naoEGostaria from '../../assets/CriacoECommerce/Option_4/naoEGostaria.svg'

import sim5 from '../../assets/CriacoECommerce/Option_5/sim.svg'
import simMasTerceiros from '../../assets/CriacoECommerce/Option_5/simMasTerceiros.svg'
import nao5 from '../../assets/CriacoECommerce/Option_5/nao.svg'
import naoEnaoSei from '../../assets/CriacoECommerce/Option_5/naoEnaoSei.svg'

import wordpress from '../../assets/CriacoECommerce/Option_6/wordpress.svg'
import htmlCssJavaScript from '../../assets/CriacoECommerce/Option_6/htmlCssJavaScript.svg'
import naoSeiGostariaOrientacao from '../../assets/CriacoECommerce/Option_6/naoSeiGostariaOrientacao.svg'
import semPreferencia from '../../assets/CriacoECommerce/Option_6/semPreferencia.svg'

import interfaceSimples from '../../assets/CriacoECommerce/Option_7/interfaceSimples.svg'
import interfacePersonalizada from '../../assets/CriacoECommerce/Option_7/interfacePersonalizada.svg'
import jaTenhoInterface from '../../assets/CriacoECommerce/Option_7/jaTenhoInterface.svg'
import naoPenseiDireito from '../../assets/CriacoECommerce/Option_7/naoPenseiDireito.svg'

import queroColocarChatbots from '../../assets/GoogleAds/Options_16/queroColocarChatbots.svg'
import apenasDatasEspeciais from '../../assets/GoogleAds/Options_6/apenasDatasEspeciais.svg'
import naoPrecisoDeRelatorio from '../../assets/GestaoDeRedes/Options_18/jaTenhoCriado.svg'

import simJaEstaTudoSeparado from '../../assets/CriacoECommerce/Option_9/simJaEstaTudoSeparado.svg'
import naoTenhoInformacoes from '../../assets/CriacoECommerce/Option_9/naoTenhoInformacoes.svg'
import naoPrecisoQueMeAjude from '../../assets/CriacoECommerce/Option_9/naoPrecisoQueMeAjude.svg'
import naoSeiDoQueTrata from '../../assets/CriacoECommerce/Option_9/naoSeiDoQueTrata.svg'

import terAlgoParaMostrar from '../../assets/CriacaoDeWebsite/Option_3/terAlgoParaMostrar.svg'
import todoMundoTem from '../../assets/CriacaoDeWebsite/Option_3/todoMundoTem.svg'

import queroTerceirizar from '../../assets/CriacoECommerce/Option_12/queroTerceirizar.svg'
import aindaNaoPensei from '../../assets/CriacoECommerce/Option_13/aindaNaoPensei.svg'
import euMesmo from '../../assets/GoogleAds/Options_16/euMesmo.svg'

import nao6 from '../../assets/GoogleAds/Options_6/nao.svg'
import atravesDeDM from '../../assets/GoogleAds/Options_15/atravesDeDM.svg'
import gostariaDeEntender from '../../assets/CriacoECommerce/Option_18/gostariaDeEntender.svg'
import masParaFrente from '../../assets/CriacoECommerce/Option_18/masParaFrente.svg'

import sim6 from '../../assets/GoogleAds/Options_6/sim.svg'
import naoSeiGostaria from '../../assets/GoogleAds/Options_6/naoSeiGostaria.svg'

import sim7 from '../../assets/GestaoDeRedes/Options_7/simJaTenhoTudo.svg'
import nao7 from '../../assets/GestaoDeRedes/Options_7/naoGostariaDeCriar.svg'
import naoMasPretendo7 from '../../assets/GestaoDeRedes/Options_7/apenasLogo.svg'
import naoSei from '../../assets/GoogleAds/Options_7/naoSei.svg'

import apenasUm from '../../assets/GoogleAds/Options_14/apenasUm.svg'
import de2a5 from '../../assets/GoogleAds/Options_14/de2a5.svg'
import de5a10 from '../../assets/GoogleAds/Options_14/de5a10.svg'
import custodiaTerceirosagencia from '../../assets/GoogleAds/Options_5/custodiaTerceirosagencia.svg'

import imediatamente from '../../assets/GoogleAds/Options_20/imediatamente.svg'
import emAte1Semana from '../../assets/GoogleAds/Options_20/emAte1Semana.svg'
import proximasSemanas from '../../assets/GoogleAds/Options_20/proximasSemanas.svg'
import maximo1Mes from '../../assets/GoogleAds/Options_20/1mes.svg'

import individual from '../../assets/form/individual.svg'
import startup from '../../assets/form/startup.svg'
import sme from '../../assets/form/sme.svg'
import agency from '../../assets/form/agency.svg'
import large_enterprise from '../../assets/form/large_enterprise.svg'
import confirm from '../../assets/form/confirm.svg'

import arrowLeft from '../../assets/arrow-left.svg'

import { Container, Content, Footer, ContainerForm, Header, CompanyTypeContainer, RadioBox, ContentHeader } from "../../styles/questionsStyles"
import { FormEvent, useEffect, useState } from 'react'
import { api, ip } from '../../services/api';

import { AnimateSharedLayout, motion } from 'framer-motion';

interface PathSelectionProps {
    onSelectPath: (selectPath: string) => void;
}

interface ErrorProps {
    name: string,
    email: string
}

export function CriacaoDeWebsite({ onSelectPath }: PathSelectionProps) {

    const [type, setType] = useState('')
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [cupomName, setCupomName] = useState('');

    const [errors, setErrors] = useState<ErrorProps>({
        name: '',
        email: ''
    })

    const [positonPath, setPositionPath] = useState(0)
    const [choice, setChoices] = useState<Object[]>([])
    const [references, setReferences] = useState({ reference1: '', reference2: '' })
    const [isLoading, setIsLoading] = useState(false);

    const containerTransation = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setReferences(refer => ({
            ...refer,
            [name]: value
        }));
    }

    async function handleExit() {
        const userIP = await ip.get("").then(response => {
            return {
                "Region": response.data.region,
                "IP": response.data.query
            }
        }).catch(() => {
            console.log('Request failed');
        })

        const choices = {
            choices: choice,
            userData: userIP
        }
        const response = await api.post('/abandonedBriefing', choices)
        console.log(response)
    };

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    })

    const alertUser = (event: any) => {
        event.preventDefault();
        event.returnValue = '';
        handleExit();
    }

    function handleNextPositionPath(question: string, choiceSelected: string | object, index: number) {
        if (positonPath > 20) return

        question = question.replace("<mark>", "").replace("</mark>", "")

        const choiceResponce = { "question": question, "answer": choiceSelected }

        const arrayChoice = [...choice]
        arrayChoice[index] = choiceResponce
        setChoices(arrayChoice)

        setPositionPath(positonPath + 1)
        window.scrollTo(0, 0)
    }

    async function handleSendBrifing(event: FormEvent) {
        event.preventDefault();
        setIsLoading(true)

        const isFormValid = validateForm();

        if (isFormValid) {

            const userIP = await ip.get("").then(response => {
                return {
                    "Região": response.data.region,
                    "IP": response.data.query
                }
            }).catch(() => {
                console.log('Request failed');
            })

            const newBriefing = {
                name: name,
                email: email,
                phone: phone,
                companyName: companyName,
                type: type,
                choices: choice,
                briefingType: "Criação de Website",
                cupomName: cupomName,
                UserDataIP: userIP
            }


            const response = await api.post('/briefing', newBriefing)
            setIsLoading(false)

            if (response) {
                setPositionPath(positonPath + 1)
            }

        } else {
            window.scrollTo(0, 0)
            setIsLoading(false)
        }
    }

    function validateForm() {
        let errors = {
            name: '',
            email: ''
        };
        let isValid = true;

        if (!name.trim()) {
            errors.name = "Campo nome obrigatorio";
            isValid = false;
        }

        if (!email) {
            errors.email = "Email obrigatorio"
            isValid = false;
        } else if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            errors.email = "Email invalido"
            isValid = false;
        }

        setErrors(errors)
        return isValid;
    }

    function handleBackPositionPath() {
        if (positonPath <= 0) onSelectPath('main')

        setPositionPath(positonPath - 1)
    }

    const questions = [
        {
            questionTitle: 'Qual nível de <mark>qualidade</mark> você está procurando?',
            answerOptions: [
                { answerText: 'Ótima qualidade', icon: otimaQualidade },
                { answerText: 'Boa relação entre qualidade e preço', icon: boaRelacao },
                { answerText: 'Qualidade não é prioridade, preciso implementar logo', icon: qualidadeNaoePrioridade }
            ]
        },
        {
            questionTitle: 'É a seu <mark>primeiro site</mark>?',
            answerOptions: [
                { answerText: 'Sim', icon: sim2 },
                { answerText: 'Não, já tenho experiência', icon: naoJaTenhoExperiencia },
                { answerText: 'Não, mas não tenho experiência', icon: naoMasNaoTenhoExperiencia },
                { answerText: 'Contratei outras pessoas mas não saiu como eu esperava', icon: contrateiOutrasPessoas }
            ]
        },
        {
            questionTitle: 'Qual a principal <mark>intenção</mark> ao criar seu site?',
            answerOptions: [
                { answerText: 'Ter algo para mostrar aos meus clientes', icon: terAlgoParaMostrar },
                { answerText: 'Preparar território para fazer anúncios pagos', icon: custodiaTerceirosagencia },
                { answerText: 'Demonstrar maior profissionalismo', icon: naoPrecisoDeRelatorio },
                { answerText: 'Todo mundo tem, eu preciso ter', icon: todoMundoTem }
            ]
        },
        {
            questionTitle: 'Você já possui um <mark>domínio</mark>?',
            answerOptions: [
                { answerText: 'Sim', icon: sim4 },
                { answerText: 'Não', icon: nao4 },
                { answerText: 'Sim, mas perdi o acesso a ele', icon: simMasPerdio },
                { answerText: 'Não, e gostaria de ajuda para escolher', icon: naoEGostaria }
            ]
        },
        {
            questionTitle: 'Você já possui algum <mark>serviço</mark> de hospedagem?',
            answerOptions: [
                { answerText: 'Sim', icon: sim5 },
                { answerText: 'Sim, mas está com terceiros', icon: simMasTerceiros },
                { answerText: 'Não', icon: nao5 },
                { answerText: 'Não, e não sei do que se trata', icon: naoEnaoSei }
            ]
        },
        {
            questionTitle: 'Quantas caixas de <mark>e-mail corporativo</mark> você vai precisar?',
            questionSubtitle: '(ex: atendimento@seudominio.com)',
            answerOptions: [
                { answerText: 'Nenhuma', icon: queroPublicar },
                { answerText: '1 a 3', icon: apenasUm },
                { answerText: '3 a 5', icon: de2a5 },
                { answerText: 'Mais de 5', icon: de5a10 }
            ]
        },
        {
            questionTitle: 'Você tem preferência por algum <mark>tipo de tecnologia</mark>?',
            answerOptions: [
                { answerText: 'Wordpress / Woocommerce', icon: wordpress },
                { answerText: 'HTML, CSS e Javascript', icon: htmlCssJavaScript },
                { answerText: 'Não sei, gostaria de mais orientações.', icon: naoSeiGostariaOrientacao },
                { answerText: 'Sem preferência', icon: semPreferencia }
            ]
        },
        {
            questionTitle: 'Qual tipo de <mark>interface</mark> você deseja?',
            answerOptions: [
                { answerText: 'Interface simples', icon: interfaceSimples },
                { answerText: 'Interface personalizada', icon: interfacePersonalizada },
                { answerText: 'Já tenho uma interface pronta.', icon: jaTenhoInterface },
                { answerText: 'Não pensei à respeito.', icon: naoPenseiDireito }
            ]
        },
        {
            questionTitle: 'Você já tem a <mark>Identidade Visual</mark> da sua marca (logo, tipografia, cores, etc)?',
            answerOptions: [
                { answerText: 'Sim, já tenho tudo', icon: sim7 },
                { answerText: 'Não, gostaria de criá-la', icon: nao7 },
                { answerText: 'Apenas a logo', icon: naoMasPretendo7 },
                { answerText: 'Não sei', icon: naoSei }
            ]
        },
        {
            questionTitle: 'Quantas <mark>páginas</mark> em média você precisa no seu site?',
            answerOptions: [
                { answerText: 'Uma única página', icon: apenasUm },
                { answerText: '5 a 8 páginas', icon: de2a5 },
                { answerText: 'Mais de 8', icon: de5a10 },
                { answerText: 'Não sei', icon: naoSeiGostaria }
            ]
        },
        {
            questionTitle: 'Você já possui organizado o <mark>conteúdo</mark> a inserir no site?',
            answerOptions: [
                { answerText: 'Sim, já está tudo separado', icon: simJaEstaTudoSeparado },
                { answerText: 'Não, tenho as informações mas preciso organizá-las', icon: naoTenhoInformacoes },
                { answerText: 'Não, preciso que me ajudem a montar do zero', icon: naoPrecisoQueMeAjude },
                { answerText: 'Não sei do que se trata', icon: naoSeiDoQueTrata }
            ]
        },
        {
            questionTitle: 'Você vai precisar de um <mark>blog</mark>?',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não', icon: nao6 },
                { answerText: 'Sim, mas gostaria de mais informações', icon: naoSei },
                { answerText: 'Não sei', icon: aindaNaoPensei }
            ]
        },
        {
            questionTitle: 'Como você quer que seus clientes <mark>entrem em contato</mark> com você através do site?',
            answerOptions: [
                { answerText: 'Apenas WhatsApp', icon: euMesmo },
                { answerText: 'WhatsApp, e-mail e ligações', icon: atravesDeDM },
                { answerText: 'Chatbots', icon: queroColocarChatbots },
                { answerText: 'Todas as demais', icon: naoPrecisoQueMeAjude }
            ]
        },
        {
            questionTitle: 'Você pretende utilizar uma estratégia de <mark>SEO</mark>?',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não', icon: nao6 },
                { answerText: 'Sim, mas gostaria de mais informações', icon: naoSei },
                { answerText: 'Não sei do que se trata', icon: naoEnaoSei }
            ]
        },
        {
            questionTitle: 'Você pretende gerar tráfego para seu site através de <mark>anúncios pagos</mark>?',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não', icon: nao6 },
                { answerText: 'Sim, mas gostaria de mais informações', icon: naoSei },
                { answerText: 'Não sei do que se trata', icon: naoEnaoSei }
            ]
        },
        {
            questionTitle: 'Com qual frequência você pretende <mark>inserir conteúdo</mark> no seu site? ',
            answerOptions: [
                { answerText: 'Frequentemente', icon: Frequentemente },
                { answerText: 'Somente em datas comemorativas', icon: apenasDatasEspeciais },
                { answerText: 'Quero apenas deixar no ar e não pensar mais nisso', icon: queroApenasDeixarNoAr },
                { answerText: 'Não sei ainda', icon: naoSei }
            ]
        },
        {
            questionTitle: 'Você pretende utilizar <mark>ferramentas de automação</mark> em seu site(chatbot, e-mail marketing etc)?',
            answerOptions: [
                { answerText: 'Sim', icon: sim6 },
                { answerText: 'Não', icon: nao6 },
                { answerText: 'Gostaria de entender mais à respeito', icon: gostariaDeEntender },
                { answerText: 'Mais pra frente', icon: masParaFrente }
            ]
        },
        {
            questionTitle: 'Qual nível de <mark>autonomia</mark> você gostaria de ter para a manutenção?',
            answerOptions: [
                { answerText: 'Eu mesmo gostaria de cuidar da manutenção.', icon: euMesmo },
                { answerText: 'Quero terceirizar a manutenção.', icon: queroTerceirizar },
                { answerText: 'Quero apenas publicar, deixar no ar e não pensar mais nisso.', icon: masParaFrente },
                { answerText: 'Ainda não pensei sobre isso.', icon: aindaNaoPensei }
            ]
        },
        {
            questionTitle: '',
            answerOptions: []
        },
        {
            questionTitle: 'Para quando você precisa do <mark>site no ar</mark>?',
            answerOptions: [
                { answerText: 'Imediatamente', icon: imediatamente },
                { answerText: 'Em até 2 semanas', icon: emAte1Semana },
                { answerText: 'Em até 1 mês, no máximo', icon: proximasSemanas },
                { answerText: 'Para os próximos meses', icon: maximo1Mes }
            ]
        }
    ]

    return (
        <>
            <Header>
                <header>
                    {positonPath < 21 &&
                        <button type="button" onClick={handleBackPositionPath}>
                            <img src={arrowLeft} alt="" />
                            <span>voltar</span>
                        </button>
                    }

                    {positonPath < 20 &&
                        <h2>{positonPath + 1}/20</h2>
                    }
                </header>
            </Header>


            {positonPath !== 18 && positonPath <= 19 &&
                <>
                    <ContentHeader>
                        <h1 dangerouslySetInnerHTML={{ __html: questions[positonPath].questionTitle }} ></h1>
                        <h2>{questions[positonPath].questionSubtitle}</h2>
                    </ContentHeader>
                    <Container>
                        <Content>
                            <AnimateSharedLayout>
                                {questions[positonPath].answerOptions.map((answerOption) => (
                                    <motion.div variants={containerTransation} initial="hidden" animate="show" key={positonPath + answerOption.answerText} className="ItemOption" onClick={() => handleNextPositionPath(questions[positonPath].questionTitle, answerOption.answerText, positonPath)}>
                                        <div className="CircleBox">
                                            <img src={answerOption.icon} alt="" />
                                        </div>
                                        <p>{answerOption.answerText}</p>
                                    </motion.div>
                                ))}
                            </AnimateSharedLayout>
                        </Content>
                    </Container>
                </>
            }

            {positonPath === 18 &&
                <Container>
                    <div className='header'>
                        <h1>Você já possui algum <mark>site</mark> que gostaria de usar como <mark>referência</mark>?</h1>
                        <h3>Se não tiver apenas deixe em branco e clique em 'avançar'</h3>
                    </div>

                    <div className="inputBox">
                        <div className="SelectOption">
                            <p>URL 1</p>
                            <input list="reference1" name='reference1' value={references.reference1} onChange={handleChange} placeholder="Cole aqui a url do site de referência" />

                        </div>
                        <div className="SelectOption">
                            <p>URL 2</p>
                            <input list="reference2" name='reference2' value={references.reference2} onChange={handleChange} placeholder="Cole aqui a url do site de referência" />
                        </div>
                        <button className="manualButton" type="button" onClick={() => handleNextPositionPath("Você já possui algum e-commerce que gostaria de usar como referência?", references, positonPath)}> Avançar</button>
                    </div>
                </Container>
            }

            {positonPath === 20 &&
                <>
                <ContentHeader>
                    <h1>Para finalizar, gostariamos de o conhecer melhor.</h1>
                </ContentHeader>
                <Container>
                    <ContainerForm onSubmit={handleSendBrifing}>
                        <div className="inputText">
                            <div>
                                <input type="text" placeholder="Nome" value={name} onChange={event => setName(event.target.value)} />
                                {errors.name && <p>{errors.name}</p>}
                            </div>
                            <div>
                                <input type="email" placeholder="Email" value={email} onChange={event => setEmail(event.target.value)} />
                                {errors.email && <p>{errors.email}</p>}
                            </div>
                            <input type="tel" placeholder="Telefone" value={phone} onChange={event => setPhone(event.target.value)} />
                            <input type="text" placeholder="Nome da Empresa" value={companyName} onChange={event => setCompanyName(event.target.value)} />
                            <input type="text" placeholder="Cupom" value={cupomName} onChange={event => setCupomName(event.target.value)} />
                        </div>
                        <CompanyTypeContainer>
                            <RadioBox
                                type="button"
                                onClick={() => { setType('individual') }}
                                isActive={type === 'individual'}
                                activeColor="green"
                            >
                                <img src={individual} alt="Individual" />
                                <span>Sou um Particular</span>
                            </RadioBox>

                            <RadioBox
                                type="button"
                                onClick={() => { setType('startup') }}
                                isActive={type === 'startup'}
                                activeColor="green"
                            >
                                <img src={startup} alt="Startup" />
                                <span>Startup</span>
                            </RadioBox>

                            <RadioBox
                                type="button"
                                onClick={() => { setType('sme') }}
                                isActive={type === 'sme'}
                                activeColor="green"
                            >
                                <img src={sme} alt="PME" />
                                <span>PME</span>
                            </RadioBox>

                            <RadioBox
                                type="button"
                                onClick={() => { setType('agency') }}
                                isActive={type === 'agency'}
                                activeColor="green"
                            >
                                <img src={agency} alt="Individual" />
                                <span>Agência</span>
                            </RadioBox>

                            <RadioBox
                                type="button"
                                onClick={() => { setType('large_enterprise') }}
                                isActive={type === 'large_enterprise'}
                                activeColor="green"
                            >
                                <img src={large_enterprise} alt="Individual" />
                                <span>Grande Empresa</span>
                            </RadioBox>

                        </CompanyTypeContainer>

                        <button type="submit" disabled={isLoading}>{isLoading && <i className="fa fa-spinner fa-spin"></i>} Finalizar</button>

                    </ContainerForm>
                </Container>
            </>
            }
            {positonPath === 21 &&
                <Container>
                    <motion.div
                        className="confirmIcon"
                        variants={containerTransation}
                        initial="hidden"
                        animate="show"
                    >
                        <img src={confirm} alt="Obrigado" />
                        <h1>Parabéns, você deu um importante passo.</h1>
                        <p>Um especialista da Brutalit entrará em contato com você o quanto antes!</p>
                        <button className="manualButton" type="button" onClick={() => { onSelectPath('main') }}>Comece um briefing de outros produtos</button>
                    </motion.div>


                </Container>
            }


            <Footer>
                <a href="https://www.brutalit.com.br"><p>Powered by Brutalit</p></a>
            </Footer>
        </>
    )
}