import styled from "styled-components"
import { transparentize } from 'polished'

export const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    height: 100vh;
`

export const Header = styled.header`
    text-align: center;
    padding: 2rem 0;

    mark{
        background: ${transparentize(0.4, '#C3F207')};
    }
`

export const Footer = styled.footer`
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    text-decoration: underline;

    a {
        text-decoration: none;
        color: black;
    }
`

export const Content = styled.div`
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;

    @media(max-width: 540px){
        padding: 0 0 2rem 0;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    .ItemOption {
        width: 13.8rem;
        margin: 2rem;
        text-align: center;

        @media(max-width: 541px){
            margin: 2rem auto;
            width: 10.8rem;
        }

        p {
            margin-top: 0.25rem;
            font-size: 1.25rem;
            font-weight: bold;
        }
    }

    .CircleBox {
        width: 100%;
        height: 13.8rem;
        border-radius: 100%;
        border: 1px solid ${transparentize(0.4, 'black')};

        display:flex;
        align-items:center;
        justify-content: center;
        

        transition: box-shadow 0.2s;

        &:hover{
            box-shadow: 0px 0px 15px -2px rgba(0,0,0,0.8);
            background: ${transparentize(0.4, '#C3F207')};
        }

        overflow: hidden;
        cursor: pointer;

        img{
            max-width: 80%;
        }

        @media(max-width: 541px){
            height: 10.8rem;
        }
    }
`