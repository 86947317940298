import { Container } from "../styles/questionsStyles";

type referenceObject = {
  reference1: string;
  reference2: string;
}

interface ReferenceInputProps {
  title: string;
  titleBox1: string;
  titleBox2: string;
  placeholder: string;
  positonPath: number;
  handleNextPositionPath: (question: string, choiceSelected: string | object, index: number) => void;
  references: referenceObject;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ReferenceInput({ title, titleBox2, titleBox1, placeholder, positonPath, handleChange, handleNextPositionPath, references }: ReferenceInputProps) {
  return (
    <Container>
      <div className='header'>
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
        <h3>Se não tiver apenas deixe em branco e clique em 'avançar'</h3>
      </div>

      <div className="inputBox">
        <div className="SelectOption">
          <p>{titleBox1}</p>
          <input list="reference1" name='reference1' value={references.reference1} onChange={handleChange} placeholder={placeholder} />

        </div>
        <div className="SelectOption">
          <p>{titleBox2}</p>
          <input list="reference2" name='reference2' value={references.reference2} onChange={handleChange} placeholder={placeholder} />
        </div>
        <button className="manualButton" type="button" onClick={() => handleNextPositionPath("Você já possui algum app que gostaria de usar como referência?", references, positonPath)}> Avançar</button>
      </div>
    </Container>
  )
}